interface IconPickerData {
  icon: string;
  category: string;
  keywords: string[];
}

export const icons: IconPickerData[] = [
  { icon: 'PiMapPin', category: 'Navigation', keywords: ['map', 'pin'] },

  { icon: 'PiAirplaneTilt', category: 'Transportation', keywords: ['airplane', 'tilt'] },
  { icon: 'PiBoat', category: 'Transportation', keywords: ['boat'] },
  { icon: 'PiBus', category: 'Transportation', keywords: ['bus'] },
  { icon: 'PiCableCar', category: 'Transportation', keywords: ['cable', 'car'] },
  { icon: 'PiCar', category: 'Transportation', keywords: ['car'] },
  { icon: 'PiCarProfile', category: 'Transportation', keywords: ['car', 'profile'] },
  { icon: 'PiMopedFront', category: 'Transportation', keywords: ['moped', 'front'] },
  { icon: 'PiMoped', category: 'Transportation', keywords: ['moped'] },
  { icon: 'PiBicycle', category: 'Transportation', keywords: ['bicycle'] },
  { icon: 'PiScooter', category: 'Transportation', keywords: ['scooter'] },
  { icon: 'PiSubway', category: 'Transportation', keywords: ['subway'] },
  { icon: 'PiTaxi', category: 'Transportation', keywords: ['taxi'] },
  { icon: 'PiTrain', category: 'Transportation', keywords: ['train'] },
  { icon: 'PiTrainRegional', category: 'Transportation', keywords: ['train', 'regional'] },
  { icon: 'PiTrainSimple', category: 'Transportation', keywords: ['train', 'simple'] },
  { icon: 'PiTram', category: 'Transportation', keywords: ['tram'] },
  { icon: 'PiTruck', category: 'Transportation', keywords: ['truck'] },
  { icon: 'PiVan', category: 'Transportation', keywords: ['van'] },
  { icon: 'PiSailboat', category: 'Transportation', keywords: ['sailboat'] },
  { icon: 'PiAnchorSimple', category: 'Nautical', keywords: ['anchor', 'simple'] },
  { icon: 'PiFlyingSaucer', category: 'Science Fiction', keywords: ['flying', 'saucer'] },
  { icon: 'PiTractor', category: 'Agriculture', keywords: ['tractor'] },
  
  { icon: 'PiBeerBottle', category: 'Beverage', keywords: ['beer', 'bottle'] },
  { icon: 'PiBeerStein', category: 'Beverage', keywords: ['beer', 'stein'] },
  { icon: 'PiBrandy', category: 'Beverage', keywords: ['brandy'] },
  { icon: 'PiChampagne', category: 'Beverage', keywords: ['champagne'] },
  
  { icon: 'PiBowlFood', category: 'Food', keywords: ['bowl', 'food'] },
  { icon: 'PiBowlSteam', category: 'Food', keywords: ['bowl', 'steam'] },
  { icon: 'PiBread', category: 'Food', keywords: ['bread'] },
  { icon: 'PiCake', category: 'Food', keywords: ['cake'] },
  { icon: 'PiCarrot', category: 'Food', keywords: ['carrot'] },
  { icon: 'PiCheese', category: 'Food', keywords: ['cheese'] },
  { icon: 'PiChefHat', category: 'Food', keywords: ['chef', 'hat'] },
  { icon: 'PiCherries', category: 'Food', keywords: ['cherries'] },
  { icon: 'PiForkKnife', category: 'Food', keywords: ['fork', 'knife'] },
  { icon: 'PiCookie', category: 'Food', keywords: ['cookie'] },
  { icon: 'PiEgg', category: 'Food', keywords: ['egg'] },
  { icon: 'PiEggCrack', category: 'Food', keywords: ['egg', 'crack'] },
  { icon: 'PiGrains', category: 'Food', keywords: ['grains'] },
  { icon: 'PiHamburger', category: 'Food', keywords: ['hamburger'] },
  { icon: 'PiIceCream', category: 'Food', keywords: ['ice', 'cream'] },
  { icon: 'PiPizza', category: 'Food', keywords: ['pizza'] },
  { icon: 'PiPopcorn', category: 'Food', keywords: ['popcorn'] },
  { icon: 'PiPopsicle', category: 'Food', keywords: ['popsicle'] },
  { icon: 'PiOrange', category: 'Food', keywords: ['orange'] },
  { icon: 'PiOrangeSlice', category: 'Food', keywords: ['orange', 'slice'] },
  { icon: 'PiPepper', category: 'Food', keywords: ['pepper'] },
  { icon: 'PiShrimp', category: 'Food', keywords: ['shrimp'] },

  { icon: 'PiBird', category: 'Animals', keywords: ['bird'] },
  { icon: 'PiButterfly', category: 'Animals', keywords: ['butterfly'] },
  { icon: 'PiCat', category: 'Animals', keywords: ['cat'] },
  { icon: 'PiCow', category: 'Animals', keywords: ['cow'] },
  { icon: 'PiFish', category: 'Animals', keywords: ['fish'] },
  { icon: 'PiHorse', category: 'Animals', keywords: ['horse'] },
  { icon: 'PiPawPrint', category: 'Animals', keywords: ['paw', 'print'] },
  { icon: 'PiRabbit', category: 'Animals', keywords: ['rabbit'] },

  { icon: 'PiBaseballCap', category: 'Clothing', keywords: ['baseball', 'cap'] },
  { icon: 'PiBasketball', category: 'Sports', keywords: ['basketball'] },
  { icon: 'PiBowlingBall', category: 'Sports', keywords: ['bowling', 'ball'] },
  { icon: 'PiBoxingGlove', category: 'Sports', keywords: ['boxing', 'glove'] },
  { icon: 'PiGolf', category: 'Sports', keywords: ['golf'] },  
  { icon: 'PiFootball', category: 'Sports', keywords: ['football'] },
  { icon: 'PiCricket', category: 'Sports', keywords: ['cricket'] },
  { icon: 'PiFootballHelmet', category: 'Sports', keywords: ['football', 'helmet'] },
  { icon: 'PiVolleyball', category: 'Sports', keywords: ['volleyball'] },
  { icon: 'PiSwimmingPool', category: 'Sports', keywords: ['swimming', 'pool'] },
  { icon: 'PiSoccerBall', category: 'Sports', keywords: ['soccer', 'ball'] },
  { icon: 'PiRacquet', category: 'Sports', keywords: ['racquet'] },
  { icon: 'PiPingPong', category: 'Sports', keywords: ['ping', 'pong'] },
  { icon: 'PiParachute', category: 'Sports', keywords: ['parachute'] },
  { icon: 'PiHockey', category: 'Sports', keywords: ['hockey'] },
  { icon: 'PiTennisBall', category: 'Sports', keywords: ['tennis', 'ball'] },

  { icon: 'PiBank', category: 'Finance', keywords: ['bank'] },
  { icon: 'PiBarn', category: 'Agriculture', keywords: ['barn'] },
  { icon: 'PiBridge', category: 'Architecture', keywords: ['bridge'] },
  { icon: 'PiBuilding', category: 'Architecture', keywords: ['building'] },
  { icon: 'PiBuildingApartment', category: 'Architecture', keywords: ['building', 'apartment'] },
  { icon: 'PiBuildingOffice', category: 'Architecture', keywords: ['building', 'office'] },
  { icon: 'PiBuildings', category: 'Architecture', keywords: ['buildings'] },
  
  { icon: 'PiBooks', category: 'Education', keywords: ['books'] },
  { icon: 'PiArmchair', category: 'Furniture', keywords: ['armchair'] },
  { icon: 'PiBasket', category: 'Household', keywords: ['basket'] },
  { icon: 'PiBathtub', category: 'Bathroom', keywords: ['bathtub'] },
  { icon: 'PiBed', category: 'Furniture', keywords: ['bed'] },
  { icon: 'PiBinoculars', category: 'Optics', keywords: ['binoculars'] },
  { icon: 'PiBook', category: 'Education', keywords: ['book'] },
  { icon: 'PiBrain', category: 'Anatomy', keywords: ['brain'] },
  { icon: 'PiBriefcase', category: 'Business', keywords: ['briefcase'] },
  { icon: 'PiCactus', category: 'Plants', keywords: ['cactus'] },
  { icon: 'PiCalendar', category: 'Time', keywords: ['calendar'] },
  { icon: 'PiCallBell', category: 'Service', keywords: ['call', 'bell'] },
  { icon: 'PiCamera', category: 'Photography', keywords: ['camera'] },
  { icon: 'PiCameraSlash', category: 'Photography', keywords: ['camera', 'slash'] },
  { icon: 'PiCampfire', category: 'Outdoor', keywords: ['campfire'] },
  { icon: 'PiCastleTurret', category: 'Architecture', keywords: ['castle', 'turret'] },
  { icon: 'PiCashRegister', category: 'Finance', keywords: ['cash', 'register'] },
  { icon: 'PiCertificate', category: 'Education', keywords: ['certificate'] },
  { icon: 'PiChair', category: 'Furniture', keywords: ['chair'] },
  { icon: 'PiChartLineUpLight', category: 'Business', keywords: ['chart', 'line', 'up', 'light'] },
  { icon: 'PiChatText', category: 'Communication', keywords: ['chat', 'text'] },
  { icon: 'PiChatCircleDots', category: 'Communication', keywords: ['chat', 'circle', 'dots'] },
  { icon: 'PiCheckCircle', category: 'Symbols', keywords: ['check', 'circle'] },
  { icon: 'PiCheck', category: 'Symbols', keywords: ['check'] },
  { icon: 'PiCheers', category: 'Celebration', keywords: ['cheers'] },
  { icon: 'PiChurch', category: 'Architecture', keywords: ['church'] },
  { icon: 'PiCigarette', category: 'Lifestyle', keywords: ['cigarette'] },
  { icon: 'PiCircle', category: 'Shapes', keywords: ['circle'] },
  { icon: 'PiCirclesFour', category: 'Shapes', keywords: ['circles', 'four'] },
  { icon: 'PiCloud', category: 'Weather', keywords: ['cloud'] },
  { icon: 'PiCloudFog', category: 'Weather', keywords: ['cloud', 'fog'] },
  { icon: 'PiCloudLightning', category: 'Weather', keywords: ['cloud', 'lightning'] },
  { icon: 'PiCloudMoon', category: 'Weather', keywords: ['cloud', 'moon'] },
  { icon: 'PiCloudRain', category: 'Weather', keywords: ['cloud', 'rain'] },
  { icon: 'PiCloudSnow', category: 'Weather', keywords: ['cloud', 'snow'] },
  { icon: 'PiCloudSun', category: 'Weather', keywords: ['cloud', 'sun'] },
  { icon: 'PiClover', category: 'Plants', keywords: ['clover'] },
  { icon: 'PiClub', category: 'Symbols', keywords: ['club'] },
  { icon: 'PiCoatHanger', category: 'Household', keywords: ['coat', 'hanger'] },
  { icon: 'PiCoffee', category: 'Beverage', keywords: ['coffee'] },
  { icon: 'PiCoffeeBean', category: 'Beverage', keywords: ['coffee', 'bean'] },
  { icon: 'PiCompass', category: 'Navigation', keywords: ['compass'] },
  { icon: 'PiConfetti', category: 'Celebration', keywords: ['confetti'] },
  { icon: 'PiCookingPot', category: 'Kitchen', keywords: ['cooking', 'pot'] },
  { icon: 'PiCouch', category: 'Furniture', keywords: ['couch'] },
  { icon: 'PiCowboyHat', category: 'Clothing', keywords: ['cowboy', 'hat'] },
  { icon: 'PiCrane', category: 'Construction', keywords: ['crane'] },
  { icon: 'PiCreditCard', category: 'Finance', keywords: ['credit', 'card'] },
  { icon: 'PiCross', category: 'Symbols', keywords: ['cross'] },
  { icon: 'PiCrown', category: 'Symbols', keywords: ['crown'] },
  { icon: 'PiCrownCross', category: 'Symbols', keywords: ['crown', 'cross'] },
  { icon: 'PiCurrencyBtc', category: 'Finance', keywords: ['currency', 'btc'] },
  { icon: 'PiCurrencyDollar', category: 'Finance', keywords: ['currency', 'dollar'] },
  { icon: 'PiCurrencyEur', category: 'Finance', keywords: ['currency', 'eur'] },
  { icon: 'PiCurrencyGbp', category: 'Finance', keywords: ['currency', 'gbp'] },
  { icon: 'PiCurrencyInr', category: 'Finance', keywords: ['currency', 'inr'] },
  { icon: 'PiCurrencyJpy', category: 'Finance', keywords: ['currency', 'jpy'] },
  { icon: 'PiCurrencyKrw', category: 'Finance', keywords: ['currency', 'krw'] },
  { icon: 'PiCurrencyKzt', category: 'Finance', keywords: ['currency', 'kzt'] },
  { icon: 'PiCurrencyNgn', category: 'Finance', keywords: ['currency', 'ngn'] },
  { icon: 'PiCurrencyRub', category: 'Finance', keywords: ['currency', 'rub'] },
  { icon: 'PiDesktopTower', category: 'Technology', keywords: ['desktop', 'tower'] },
  { icon: 'PiDiceFive', category: 'Games', keywords: ['dice', 'five'] },
  { icon: 'PiDiscoBall', category: 'Entertainment', keywords: ['disco', 'ball'] },
  { icon: 'PiDress', category: 'Clothing', keywords: ['dress'] },
  { icon: 'PiEar', category: 'Anatomy', keywords: ['ear'] },
  { icon: 'PiEarSlash', category: 'Anatomy', keywords: ['ear', 'slash'] },
  { icon: 'PiElevator', category: 'Architecture', keywords: ['elevator'] },
  { icon: 'PiEnvelope', category: 'Communication', keywords: ['envelope'] },
  { icon: 'PiEnvelopeOpen', category: 'Communication', keywords: ['envelope', 'open'] },
  { icon: 'PiEqualizer', category: 'Music', keywords: ['equalizer'] },
  { icon: 'PiExam', category: 'Education', keywords: ['exam'] },
  { icon: 'PiEye', category: 'Anatomy', keywords: ['eye'] },
  { icon: 'PiEyeSlash', category: 'Anatomy', keywords: ['eye', 'slash'] },
  { icon: 'PiEyeglasses', category: 'Accessories', keywords: ['eyeglasses'] },
  { icon: 'PiFactory', category: 'Industry', keywords: ['factory'] },
  { icon: 'PiFalloutShelter', category: 'Safety', keywords: ['fallout', 'shelter'] },
  { icon: 'PiFan', category: 'Appliances', keywords: ['fan'] },
  { icon: 'PiFarm', category: 'Agriculture', keywords: ['farm'] },
  { icon: 'PiFeather', category: 'Nature', keywords: ['feather'] },
  { icon: 'PiFilmReel', category: 'Entertainment', keywords: ['film', 'reel'] },
  { icon: 'PiFilmSlate', category: 'Entertainment', keywords: ['film', 'slate'] },
  { icon: 'PiFilmStrip', category: 'Entertainment', keywords: ['film', 'strip'] },
  { icon: 'PiFingerprint', category: 'Security', keywords: ['fingerprint'] },
  { icon: 'PiFire', category: 'Nature', keywords: ['fire'] },
  { icon: 'PiFireExtinguisher', category: 'Safety', keywords: ['fire', 'extinguisher'] },
  { icon: 'PiFireTruck', category: 'Emergency', keywords: ['fire', 'truck'] },
  { icon: 'PiFirstAid', category: 'Health', keywords: ['first', 'aid'] },
  { icon: 'PiFirstAidKit', category: 'Health', keywords: ['first', 'aid', 'kit'] },
  { icon: 'PiFlag', category: 'Symbols', keywords: ['flag'] },
  { icon: 'PiFlagBanner', category: 'Symbols', keywords: ['flag', 'banner'] },
  { icon: 'PiFlagBannerFold', category: 'Symbols', keywords: ['flag', 'banner', 'fold'] },
  { icon: 'PiFlagCheckered', category: 'Symbols', keywords: ['flag', 'checkered'] },
  { icon: 'PiFlagPennant', category: 'Symbols', keywords: ['flag', 'pennant'] },
  { icon: 'PiFlame', category: 'Nature', keywords: ['flame'] },
  { icon: 'PiFlashlight', category: 'Tools', keywords: ['flashlight'] },
  { icon: 'PiFlask', category: 'Science', keywords: ['flask'] },
  { icon: 'PiFlowArrow', category: 'Symbols', keywords: ['flow', 'arrow'] },
  { icon: 'PiFlower', category: 'Nature', keywords: ['flower'] },
  { icon: 'PiFlowerLotus', category: 'Nature', keywords: ['flower', 'lotus'] },
  { icon: 'PiFlowerTulip', category: 'Nature', keywords: ['flower', 'tulip'] },

  { icon: 'PiFootprints', category: 'Nature', keywords: ['footprints'] },

  { icon: 'PiGameController', category: 'Games', keywords: ['game', 'controller'] },
  { icon: 'PiGarage', category: 'Architecture', keywords: ['garage'] },
  { icon: 'PiGasCan', category: 'Tools', keywords: ['gas', 'can'] },
  { icon: 'PiGasPump', category: 'Tools', keywords: ['gas', 'pump'] },
  { icon: 'PiGauge', category: 'Tools', keywords: ['gauge'] },
  { icon: 'PiGavel', category: 'Law', keywords: ['gavel'] },
  { icon: 'PiGear', category: 'Tools', keywords: ['gear'] },
  { icon: 'PiGhost', category: 'Fantasy', keywords: ['ghost'] },
  { icon: 'PiGift', category: 'Celebration', keywords: ['gift'] },
  { icon: 'PiGlobe', category: 'Geography', keywords: ['globe'] },
  { icon: 'PiGlobeHemisphereEast', category: 'Geography', keywords: ['globe', 'hemisphere', 'east'] },
  { icon: 'PiGlobeHemisphereWest', category: 'Geography', keywords: ['globe', 'hemisphere', 'west'] },
  { icon: 'PiGlobeStand', category: 'Geography', keywords: ['globe', 'stand'] },
  { icon: 'PiGps', category: 'Navigation', keywords: ['gps'] },
  { icon: 'PiGraduationCap', category: 'Education', keywords: ['graduation', 'cap'] },

  { icon: 'PiGuitar', category: 'Music', keywords: ['guitar'] },
  { icon: 'PiHairDryer', category: 'Appliances', keywords: ['hair', 'dryer'] },
  { icon: 'PiHand', category: 'Anatomy', keywords: ['hand'] },
  { icon: 'PiHandFist', category: 'Anatomy', keywords: ['hand', 'fist'] },
  { icon: 'PiHandGrabbing', category: 'Anatomy', keywords: ['hand', 'grabbing'] },
  { icon: 'PiHandHeart', category: 'Symbols', keywords: ['hand', 'heart'] },
  { icon: 'PiHandPeace', category: 'Symbols', keywords: ['hand', 'peace'] },
  { icon: 'PiHandPointing', category: 'Anatomy', keywords: ['hand', 'pointing'] },
  { icon: 'PiHandWaving', category: 'Anatomy', keywords: ['hand', 'waving'] },
  { icon: 'PiHandbag', category: 'Accessories', keywords: ['handbag'] },
  { icon: 'PiHandsPraying', category: 'Symbols', keywords: ['hands', 'praying'] },
  { icon: 'PiHandshake', category: 'Symbols', keywords: ['handshake'] },
  { icon: 'PiHeadphones', category: 'Music', keywords: ['headphones'] },
  { icon: 'PiHeart', category: 'Symbols', keywords: ['heart'] },
  { icon: 'PiHeartBreak', category: 'Symbols', keywords: ['heart', 'break'] },
  { icon: 'PiHighHeel', category: 'Clothing', keywords: ['high', 'heel'] },
  { icon: 'PiHoodie', category: 'Clothing', keywords: ['hoodie'] },
  { icon: 'PiHospital', category: 'Health', keywords: ['hospital'] },
  { icon: 'PiHouse', category: 'Architecture', keywords: ['house'] },
  { icon: 'PiHurricane', category: 'Weather', keywords: ['hurricane'] },
  { icon: 'PiIdentificationCard', category: 'Identity', keywords: ['identification', 'card'] },
  { icon: 'PiImage', category: 'Photography', keywords: ['image'] },
  { icon: 'PiInfo', category: 'Symbols', keywords: ['info'] },
  { icon: 'PiInfinity', category: 'Symbols', keywords: ['infinity'] },
  { icon: 'PiIsland', category: 'Geography', keywords: ['island'] },
  { icon: 'PiJar', category: 'Kitchen', keywords: ['jar'] },
  { icon: 'PiJarLabel', category: 'Kitchen', keywords: ['jar', 'label'] },
  { icon: 'PiJeep', category: 'Transportation', keywords: ['jeep'] },
  { icon: 'PiJoystick', category: 'Games', keywords: ['joystick'] },
  { icon: 'PiKey', category: 'Security', keywords: ['key'] },
  { icon: 'PiKeyhole', category: 'Security', keywords: ['keyhole'] },
  { icon: 'PiKnife', category: 'Kitchen', keywords: ['knife'] },
  { icon: 'PiLadder', category: 'Tools', keywords: ['ladder'] },
  { icon: 'PiLampPendant', category: 'Lighting', keywords: ['lamp', 'pendant'] },
  { icon: 'PiLaptop', category: 'Technology', keywords: ['laptop'] },
  { icon: 'PiLego', category: 'Toys', keywords: ['lego'] },
  { icon: 'PiLifebuoy', category: 'Safety', keywords: ['lifebuoy'] },
  { icon: 'PiLightbulb', category: 'Lighting', keywords: ['lightbulb'] },
  { icon: 'PiLighthouse', category: 'Architecture', keywords: ['lighthouse'] },
  { icon: 'PiLink', category: 'Symbols', keywords: ['link'] },
  { icon: 'PiLock', category: 'Security', keywords: ['lock'] },
  { icon: 'PiLockKeyOpen', category: 'Security', keywords: ['lock', 'key', 'open'] },
  { icon: 'PiLog', category: 'Nature', keywords: ['log'] },
  { icon: 'PiMagicWand', category: 'Fantasy', keywords: ['magic', 'wand'] },
  { icon: 'PiMagnet', category: 'Science', keywords: ['magnet'] },
  { icon: 'PiMagnifyingGlass', category: 'Tools', keywords: ['magnifying', 'glass'] },
  { icon: 'PiMailbox', category: 'Communication', keywords: ['mailbox'] },
  { icon: 'PiMapTrifold', category: 'Navigation', keywords: ['map', 'trifold'] },
  { icon: 'PiMartini', category: 'Beverage', keywords: ['martini'] },
  { icon: 'PiMaskHappy', category: 'Symbols', keywords: ['mask', 'happy'] },
  { icon: 'PiMaskSad', category: 'Symbols', keywords: ['mask', 'sad'] },
  { icon: 'PiMedal', category: 'Awards', keywords: ['medal'] },
  { icon: 'PiMegaphone', category: 'Communication', keywords: ['megaphone'] },
  { icon: 'PiMeteor', category: 'Space', keywords: ['meteor'] },
  { icon: 'PiMetronome', category: 'Music', keywords: ['metronome'] },
  { icon: 'PiMicrophone', category: 'Music', keywords: ['microphone'] },
  { icon: 'PiMicrophoneStage', category: 'Music', keywords: ['microphone', 'stage'] },
  { icon: 'PiMicroscope', category: 'Science', keywords: ['microscope'] },
  { icon: 'PiMoney', category: 'Finance', keywords: ['money'] },
  { icon: 'PiMoneyWavy', category: 'Finance', keywords: ['money', 'wavy'] },
  { icon: 'PiMoon', category: 'Space', keywords: ['moon'] },
  { icon: 'PiMoonStars', category: 'Space', keywords: ['moon', 'stars'] },
  { icon: 'PiMosque', category: 'Architecture', keywords: ['mosque'] },
  { icon: 'PiMotorcycle', category: 'Transportation', keywords: ['motorcycle'] },
  { icon: 'PiMountains', category: 'Nature', keywords: ['mountains'] },
  { icon: 'PiMusicNote', category: 'Music', keywords: ['music', 'note'] },
  { icon: 'PiMusicNoteSimple', category: 'Music', keywords: ['music', 'note', 'simple'] },
  { icon: 'PiMusicNotes', category: 'Music', keywords: ['music', 'notes'] },
  { icon: 'PiNavigationArrow', category: 'Navigation', keywords: ['navigation', 'arrow'] },
  { icon: 'PiNewspaper', category: 'Media', keywords: ['newspaper'] },
  { icon: 'PiNuclearPlant', category: 'Industry', keywords: ['nuclear', 'plant'] },
  { icon: 'PiNumberCircleOne', category: 'Symbols', keywords: ['number', 'circle', 'one'] },
  { icon: 'PiNumberCircleTwo', category: 'Symbols', keywords: ['number', 'circle', 'two'] },
  { icon: 'PiNumberCircleThree', category: 'Symbols', keywords: ['number', 'circle', 'three'] },
  { icon: 'PiNumberCircleFour', category: 'Symbols', keywords: ['number', 'circle', 'four'] },
  { icon: 'PiNumberCircleFive', category: 'Symbols', keywords: ['number', 'circle', 'five'] },
  { icon: 'PiNumberCircleSix', category: 'Symbols', keywords: ['number', 'circle', 'six'] },
  { icon: 'PiNumberCircleSeven', category: 'Symbols', keywords: ['number', 'circle', 'seven'] },
  { icon: 'PiNumberCircleEight', category: 'Symbols', keywords: ['number', 'circle', 'eight'] },
  { icon: 'PiNumberCircleNine', category: 'Symbols', keywords: ['number', 'circle', 'nine'] },
  { icon: 'PiOfficeChair', category: 'Furniture', keywords: ['office', 'chair'] },
  { icon: 'PiPaintBrush', category: 'Art', keywords: ['paint', 'brush'] },
  { icon: 'PiPaintBucket', category: 'Art', keywords: ['paint', 'bucket'] },
  { icon: 'PiPalette', category: 'Art', keywords: ['palette'] },
  { icon: 'PiPanorama', category: 'Photography', keywords: ['panorama'] },
  { icon: 'PiPants', category: 'Clothing', keywords: ['pants'] },
  { icon: 'PiPark', category: 'Nature', keywords: ['park'] },
  { icon: 'PiPath', category: 'Nature', keywords: ['path'] },
  { icon: 'PiPen', category: 'Writing', keywords: ['pen'] },
  { icon: 'PiPenNib', category: 'Writing', keywords: ['pen', 'nib'] },
  { icon: 'PiPencilRuler', category: 'Tools', keywords: ['pencil', 'ruler'] },
  { icon: 'PiPerson', category: 'People', keywords: ['person'] },
  { icon: 'PiPersonArmsSpread', category: 'People', keywords: ['person', 'arms', 'spread'] },
  { icon: 'PiPersonSimpleBike', category: 'People', keywords: ['person', 'simple', 'bike'] },
  { icon: 'PiPersonSimpleHike', category: 'People', keywords: ['person', 'simple', 'hike'] },
  { icon: 'PiPersonSimpleRun', category: 'People', keywords: ['person', 'simple', 'run'] },
  { icon: 'PiPersonSimpleSki', category: 'People', keywords: ['person', 'simple', 'ski'] },
  { icon: 'PiPersonSimpleSnowboard', category: 'People', keywords: ['person', 'simple', 'snowboard'] },
  { icon: 'PiPersonSimpleSwim', category: 'People', keywords: ['person', 'simple', 'swim'] },
  { icon: 'PiPersonSimpleTaiChi', category: 'People', keywords: ['person', 'simple', 'tai', 'chi'] },
  { icon: 'PiPersonSimpleThrow', category: 'People', keywords: ['person', 'simple', 'throw'] },
  { icon: 'PiPersonSimpleWalk', category: 'People', keywords: ['person', 'simple', 'walk'] },
  { icon: 'PiPhone', category: 'Communication', keywords: ['phone'] },
  { icon: 'PiPhoneCall', category: 'Communication', keywords: ['phone', 'call'] },
  { icon: 'PiPianoKeys', category: 'Music', keywords: ['piano', 'keys'] },
  { icon: 'PiPiggyBank', category: 'Finance', keywords: ['piggy', 'bank'] },
  { icon: 'PiPlanet', category: 'Space', keywords: ['planet'] },
  { icon: 'PiPlant', category: 'Nature', keywords: ['plant'] },
  { icon: 'PiPlug', category: 'Technology', keywords: ['plug'] },
  { icon: 'PiPottedPlant', category: 'Nature', keywords: ['potted', 'plant'] },
  { icon: 'PiPushPin', category: 'Office', keywords: ['push', 'pin'] },
  { icon: 'PiPuzzlePiece', category: 'Games', keywords: ['puzzle', 'piece'] },
  { icon: 'PiQuestion', category: 'Symbols', keywords: ['question'] },
  { icon: 'PiRadio', category: 'Technology', keywords: ['radio'] },
  { icon: 'PiRainbow', category: 'Nature', keywords: ['rainbow'] },
  { icon: 'PiRainbowCloud', category: 'Nature', keywords: ['rainbow', 'cloud'] },
  { icon: 'PiRocket', category: 'Space', keywords: ['rocket'] },
  { icon: 'PiRocketLaunch', category: 'Space', keywords: ['rocket', 'launch'] },
  { icon: 'PiSealCheck', category: 'Symbols', keywords: ['seal', 'check'] },
  { icon: 'PiSecurityCamera', category: 'Security', keywords: ['security', 'camera'] },
  { icon: 'PiShield', category: 'Security', keywords: ['shield'] },
  { icon: 'PiShirtFolded', category: 'Clothing', keywords: ['shirt', 'folded'] },
  { icon: 'PiShootingStar', category: 'Space', keywords: ['shooting', 'star'] },
  { icon: 'PiShoppingBagOpen', category: 'Shopping', keywords: ['shopping', 'bag', 'open'] },
  { icon: 'PiShoppingCart', category: 'Shopping', keywords: ['shopping', 'cart'] },
  { icon: 'PiShovel', category: 'Tools', keywords: ['shovel'] },
  { icon: 'PiShower', category: 'Bathroom', keywords: ['shower'] },
  { icon: 'PiSignature', category: 'Writing', keywords: ['signature'] },
  { icon: 'PiSignpost', category: 'Navigation', keywords: ['signpost'] },
  { icon: 'PiSketchLogo', category: 'Design', keywords: ['sketch', 'logo'] },
  { icon: 'PiSkull', category: 'Symbols', keywords: ['skull'] },
  { icon: 'PiSmiley', category: 'Symbols', keywords: ['smiley'] },
  { icon: 'PiSmileyAngry', category: 'Symbols', keywords: ['smiley', 'angry'] },
  { icon: 'PiSmileyBlank', category: 'Symbols', keywords: ['smiley', 'blank'] },
  { icon: 'PiSmileyMeh', category: 'Symbols', keywords: ['smiley', 'meh'] },
  { icon: 'PiSmileyMelting', category: 'Symbols', keywords: ['smiley', 'melting'] },
  { icon: 'PiSmileyNervous', category: 'Symbols', keywords: ['smiley', 'nervous'] },
  { icon: 'PiSmileySad', category: 'Symbols', keywords: ['smiley', 'sad'] },
  { icon: 'PiSmileyWink', category: 'Symbols', keywords: ['smiley', 'wink'] },
  { icon: 'PiSmileyXEyes', category: 'Symbols', keywords: ['smiley', 'x', 'eyes'] },
  { icon: 'PiSneaker', category: 'Clothing', keywords: ['sneaker'] },
  { icon: 'PiSneakerMove', category: 'Clothing', keywords: ['sneaker', 'move'] },
  { icon: 'PiSnowflake', category: 'Weather', keywords: ['snowflake'] },

  { icon: 'PiSock', category: 'Clothing', keywords: ['sock'] },
  { icon: 'PiSpade', category: 'Symbols', keywords: ['spade'] },
  { icon: 'PiSparkle', category: 'Symbols', keywords: ['sparkle'] },
  { icon: 'PiSpeakerHifi', category: 'Music', keywords: ['speaker', 'hifi'] },
  { icon: 'PiSpeakerHigh', category: 'Music', keywords: ['speaker', 'high'] },
  { icon: 'PiSpinnerBall', category: 'Games', keywords: ['spinner', 'ball'] },
  { icon: 'PiStar', category: 'Symbols', keywords: ['star'] },
  { icon: 'PiStarAndCrescent', category: 'Symbols', keywords: ['star', 'crescent'] },
  { icon: 'PiStarOfDavid', category: 'Symbols', keywords: ['star', 'david'] },
  { icon: 'PiStarFour', category: 'Symbols', keywords: ['star', 'four'] },
  { icon: 'PiSticker', category: 'Symbols', keywords: ['sticker'] },
  { icon: 'PiStorefront', category: 'Shopping', keywords: ['storefront'] },
  { icon: 'PiStudent', category: 'Education', keywords: ['student'] },
  { icon: 'PiSuitcase', category: 'Travel', keywords: ['suitcase'] },
  { icon: 'PiSuitcaseRolling', category: 'Travel', keywords: ['suitcase', 'rolling'] },
  { icon: 'PiSuitcaseSimple', category: 'Travel', keywords: ['suitcase', 'simple'] },
  { icon: 'PiSun', category: 'Weather', keywords: ['sun'] },
  { icon: 'PiSunDim', category: 'Weather', keywords: ['sun', 'dim'] },
  { icon: 'PiSunHorizon', category: 'Weather', keywords: ['sun', 'horizon'] },
  { icon: 'PiSunglasses', category: 'Accessories', keywords: ['sunglasses'] },
  { icon: 'PiSword', category: 'Weapons', keywords: ['sword'] },
  { icon: 'PiSynagogue', category: 'Architecture', keywords: ['synagogue'] },
  { icon: 'PiTShirt', category: 'Clothing', keywords: ['tshirt'] },
  { icon: 'PiTag', category: 'Shopping', keywords: ['tag'] },
  { icon: 'PiTeaBag', category: 'Beverage', keywords: ['tea', 'bag'] },
  { icon: 'PiTelevision', category: 'Technology', keywords: ['television'] },
  { icon: 'PiTent', category: 'Outdoor', keywords: ['tent'] },
  { icon: 'PiThermometer', category: 'Weather', keywords: ['thermometer'] },
  { icon: 'PiThumbsDown', category: 'Symbols', keywords: ['thumbs', 'down'] },
  { icon: 'PiThumbsUp', category: 'Symbols', keywords: ['thumbs', 'up'] },
  { icon: 'PiTipi', category: 'Architecture', keywords: ['tipi'] },
  { icon: 'PiTire', category: 'Transportation', keywords: ['tire'] },
  { icon: 'PiTornado', category: 'Weather', keywords: ['tornado'] },
  { icon: 'PiTrafficSign', category: 'Transportation', keywords: ['traffic', 'sign'] },
  { icon: 'PiTrafficSignal', category: 'Transportation', keywords: ['traffic', 'signal'] },
  { icon: 'PiTree', category: 'Nature', keywords: ['tree'] },
  { icon: 'PiTreeEvergreen', category: 'Nature', keywords: ['tree', 'evergreen'] },
  { icon: 'PiTreePalm', category: 'Nature', keywords: ['tree', 'palm'] },
  { icon: 'PiTrendDown', category: 'Finance', keywords: ['trend', 'down'] },
  { icon: 'PiTrendUp', category: 'Finance', keywords: ['trend', 'up'] },
  { icon: 'PiTrolleySuitcase', category: 'Travel', keywords: ['trolley', 'suitcase'] },
  { icon: 'PiTrophy', category: 'Awards', keywords: ['trophy'] },
  { icon: 'PiUmbrella', category: 'Weather', keywords: ['umbrella'] },
  { icon: 'PiUser', category: 'People', keywords: ['user'] },
  { icon: 'PiUsers', category: 'People', keywords: ['users'] },
  { icon: 'PiVault', category: 'Security', keywords: ['vault'] },
  { icon: 'PiVideoCamera', category: 'Technology', keywords: ['video', 'camera'] },
  { icon: 'PiVinylRecord', category: 'Music', keywords: ['vinyl', 'record'] },
  { icon: 'PiVirus', category: 'Health', keywords: ['virus'] },

  { icon: 'PiWall', category: 'Architecture', keywords: ['wall'] },
  { icon: 'PiWallet', category: 'Finance', keywords: ['wallet'] },
  { icon: 'PiWarehouse', category: 'Industry', keywords: ['warehouse'] },
  { icon: 'PiWarning', category: 'Symbols', keywords: ['warning'] },
  { icon: 'PiWarningCircle', category: 'Symbols', keywords: ['warning', 'circle'] },
  { icon: 'PiWarningDiamond', category: 'Symbols', keywords: ['warning', 'diamond'] },
  { icon: 'PiWatch', category: 'Accessories', keywords: ['watch'] },
  { icon: 'PiWindmill', category: 'Energy', keywords: ['windmill'] },
  { icon: 'PiWind', category: 'Weather', keywords: ['wind'] },
  { icon: 'PiWine', category: 'Beverage', keywords: ['wine'] },
  { icon: 'PiYarn', category: 'Crafts', keywords: ['yarn'] },
];

export const defaultIcon = icons.find(i => i.icon === 'PiGlobeHemisphereEast')!;
