import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import mapReducer from './slices/mapSlice';
import tripStopReducer from './slices/tripStopSlice';
import { baseApi } from './api/baseApi';

const store = configureStore({
  reducer: {
    auth: authReducer,
    map: mapReducer,
    tripStops: tripStopReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;