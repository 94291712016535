import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../../models/Location';
import { MapMarkerData } from '../../models/MapMarkerData';

interface MapState {
  center: Location;
  isClickable: boolean;
  isFlyByMode: boolean;
  addNewSpotMarker: MapMarkerData | null;
  selectedMarkerStyle: MapMarkerData | null;
}

const initialState: MapState = {
  center: { latitude: 50, longitude: 14.4 },
  isClickable: false,
  isFlyByMode: false,
  addNewSpotMarker: null,
  selectedMarkerStyle: null,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapCenter(state, action: PayloadAction<Location>) {
      state.center = action.payload;
    },
    setMapClickable(state, action: PayloadAction<boolean>) {
      state.isClickable = action.payload;
    },
    setFlyByMode(state, action: PayloadAction<boolean>) {
      state.isFlyByMode = action.payload;
    },
    setAddNewSpotMarker(state, action: PayloadAction<MapMarkerData | null>) {
      state.addNewSpotMarker = action.payload;
    },
    setSelectedMarkerStyle(state, action: PayloadAction<MapMarkerData | null>) {
      state.selectedMarkerStyle = action.payload;
    },
  },
});

export const { setMapCenter, setMapClickable, setFlyByMode, setAddNewSpotMarker, setSelectedMarkerStyle } = mapSlice.actions;
export default mapSlice.reducer;
