import { TripStop } from '../../models/TripStop';
import { baseApi } from './baseApi';

export const tripStopsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTripStops: builder.query<TripStop[], void>({
      query: () => '/trip-stops',
      providesTags: ['TripStop'],
    }),
    addTripStop: builder.mutation<TripStop, Partial<TripStop>>({
      query: (tripStop) => ({
        url: '/trip-stops',
        method: 'POST',
        body: tripStop,
      }),
      invalidatesTags: ['TripStop'],
    }),
    updateTripStop: builder.mutation<TripStop, TripStop>({
      query: (tripStop) => ({
        url: `/trip-stops/${tripStop.id}`,
        method: 'PUT',
        body: tripStop,
      }),
      invalidatesTags: ['TripStop'],
    }),
    deleteTripStop: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trip-stops/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TripStop'],
    }),
    addTripStopPhoto: builder.mutation<TripStop, { tripStopId: string; fileId: string }>({
      query: ({ tripStopId, fileId }) => ({
        url: `/trip-stops/${tripStopId}/photos`,
        method: 'POST',
        body: { fileId }
      }),
      invalidatesTags: ['TripStop', 'Trip'],
    }),
    deleteTripStopPhoto: builder.mutation<TripStop, { tripStopId: string; fileId: string }>({
      query: ({ tripStopId, fileId }) => ({
        url: `/trip-stops/${tripStopId}/photos/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TripStop', 'Trip'],
    }),
  }),
});

export const {
  useGetAllTripStopsQuery,
  useAddTripStopMutation,
  useUpdateTripStopMutation,
  useDeleteTripStopMutation,
  useAddTripStopPhotoMutation,
  useDeleteTripStopPhotoMutation,
} = tripStopsApi; 