import { baseApi } from './baseApi';
import { GeocodingResult } from '../../models/GeocodingResult';
import { Location } from '../../models/Location';

export const geocodingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    searchLocation: builder.query<GeocodingResult[], { searchQuery: string; location?: Location }>({
      query: ({ searchQuery, location }) => ({
        url: '/geocoding',
        params: { 
          searchQuery,
          latitude: location?.latitude,
          longitude: location?.longitude 
        }
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        return newItems;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.searchQuery !== previousArg?.searchQuery;
      },
    }),
    reverseGeocode: builder.query<GeocodingResult[], Location>({
      query: (location) => ({
        url: '/geocoding/reverse',
        params: { 
          latitude: location.latitude,
          longitude: location.longitude 
        }
      }),
    }),
  }),
});

export const {
  useLazySearchLocationQuery,
  useLazyReverseGeocodeQuery,
} = geocodingApi; 