import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTokens } from '../../redux/slices/authSlice';
import { Button, Flex, Form, Input, notification } from 'antd';
import { useLoginMutation } from '../../redux/api/authApi';
import { Login } from '../../models/Login';

const schema = z.object({
  email: z.string().email("Invalid email format"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

const LoginPage: React.FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Login>({
    resolver: zodResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [api, contextHolder] = notification.useNotification();

  const onSubmit = async (data: Login) => {
    try {
      const result = await login(data).unwrap();
      dispatch(setTokens({
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
      }));
      navigate('/');
    } catch (error) {
      console.error('Login error', error);
      api.warning({
        message: 'Login failed',
        description: 'Incorrect username or password. Please try again'
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <h1>Welcome back, traveler</h1>
      <h2>Log in to dive into your travel memories and add new ones</h2>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Form.Item
          label="Email address"
          validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input {...field} placeholder='j.doe@email.com' />}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          validateStatus={errors.password ? 'error' : ''}
          help={errors.password ? errors.password.message : ''}
        >
          <Controller
            name="password"
            control={control}
            render={({ field }) => <Input.Password {...field} placeholder="Your password" />}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right' }}>
          <Flex justify='space-between' align='center'>
            <Button type="text" htmlType="button" onClick={() => navigate('/auth/register')}>
              Create an account
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isLoading ? 'Signing in...' : 'Sign in'}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </div>
)};

export default LoginPage;
