import React from 'react';
import { Upload } from 'antd';
import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useUploadFileMutation } from '../redux/api/filesApi';

const { Dragger } = Upload;

const PhotoUpload: React.FC<{ onUploadSuccess: (id: string) => void, onUploadError: (error: string) => void }> = ({ onUploadSuccess, onUploadError }) => {
  const [uploadFile] = useUploadFileMutation();
  
  const handleUpload: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
    try {
      const response = await uploadFile(file as File).unwrap();
      onSuccess && onSuccess(response, file);
      onUploadSuccess(response.id);
    } catch (error) {
      onUploadError(error as string);
      onError && onError(error as Error);
    }
  };

  return (
    <Dragger showUploadList={false} customRequest={handleUpload} multiple={true} accept="image/*,video/*,video/mp4,video/x-m4v" >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint"></p>
    </Dragger>
  );
};

export default PhotoUpload;
