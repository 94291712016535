import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import Map, { LngLatLike, MapLayerMouseEvent, MapRef, Marker, ViewStateChangeEvent } from 'react-map-gl/maplibre';
import { TripStop } from '../../models/TripStop';
import { createSvg } from '../../utils/markers';
import { defaultIcon, icons } from '../../utils/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { setAddNewSpotMarker, setFlyByMode, setMapCenter } from '../../redux/slices/mapSlice';
import { darken, defaultColor } from '../../utils/color';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultCenter, defaultZoom, maxZoom, minZoom } from './MapConstants';
import MapIcon from './MapIcon';
import { Location } from '../../models/Location';
import { MapStyle } from './MapStyle';
interface MapProps {
  mapStyle: MapStyle;
  tripStops: TripStop[];
  homeLocation?: Location;
}

const StopMap: React.FC<MapProps> = ({ tripStops, mapStyle, homeLocation }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { tripId } = useParams<{ tripId: string }>();

  const mapRef = useRef<MapRef | null>(null);

  const isClickable = useSelector((state: RootState) => state.map.isClickable);
  const addNewSpotMarker = useSelector((state: RootState) => state.map.addNewSpotMarker);
  const isFlyByMode = useSelector((state: RootState) => state.map.isFlyByMode);
  const selectedStopId = useSelector((state: RootState) => state.tripStops.selectedStopId);
  const selectedMarkerStyle = useSelector((state: RootState) => state.map.selectedMarkerStyle);

  const [selectedStop, setSelectedStop] = useState<TripStop | null>(null);

  const styleSource = useMemo(() =>
    mapStyle === MapStyle.Road
      ? 'https://tiles.openfreemap.org/styles/liberty'
      : 'https://raw.githubusercontent.com/go2garret/maps/main/src/assets/json/arcgis_hybrid.json',
    [mapStyle]
  );

  useEffect(() => {
    if (mapRef.current && tripStops.length > 0 && !isFlyByMode) {

      const lats = tripStops.map(stop => stop.location.latitude)
      const lngs = tripStops.map(stop => stop.location.longitude);
      if(homeLocation) {
        lats.push(homeLocation.latitude);
        lngs.push(homeLocation.longitude);
      }
      const southWest: [number, number] = [Math.min(...lngs), Math.min(...lats)];
      const northEast: [number, number] = [Math.max(...lngs), Math.max(...lats)];

      mapRef.current.fitBounds([southWest, northEast] as [LngLatLike, LngLatLike], {
        padding: 150,
        maxZoom: 15,
        duration: 1200,
        pitch: 0
      });
    }

    if (mapRef.current && selectedStopId) {
      var selectedStop = tripStops.find(ts => ts.id === selectedStopId);
      if (selectedStop) {
        mapRef.current.flyTo({
          center: [selectedStop.location.longitude, selectedStop.location.latitude],
          zoom: 18,
          duration: 1200,
          pitch: 60,
          bearing: 30
        });
      }
    }

    if (mapRef.current && addNewSpotMarker?.location) {
      mapRef.current.flyTo({
        center: [addNewSpotMarker.location.longitude, addNewSpotMarker.location.latitude],
        zoom: mapRef.current.getZoom(),
        duration: 1200
      });
    }
  }, [tripStops, mapRef, addNewSpotMarker, isFlyByMode, selectedStopId, mapStyle]);

  //Trip replay mode
  useEffect(() => {
    if (!isFlyByMode) return;
    let loopTimeout: NodeJS.Timeout;

    const runLoop = async () => {
      for (let i = 0; i < tripStops.length; i++) {
        if (!isFlyByMode) {
          console.log('end');
          setSelectedStop(null);
          break;
        };

        var stop = tripStops[i];

        if (mapRef?.current && stop) {
          mapRef.current.flyTo({
            center: [stop.location.longitude, stop.location.latitude],
            zoom: 18,
            duration: 1500,
            pitch: 60,
            bearing: 30 * i,
            essential: true,
          });

          //mapRef.current.rotateTo(360, { duration: 150 });
          setSelectedStop(tripStops[i]);
        }

        if (i === tripStops.length - 1) {
          setTimeout(() => {
            dispatch(setFlyByMode(false));
            setSelectedStop(null);
          }, 6500)
        }

        await new Promise<void>((resolve) => {
          loopTimeout = setTimeout(resolve, 6500);
        });
      }
    };

    runLoop();
    return () => {
      clearTimeout(loopTimeout);
    };
  }, [isFlyByMode, mapRef, dispatch, tripStops, setSelectedStop]);


  // Update view state when home location changes
  useEffect(() => {
    if (mapRef.current && homeLocation && !isFlyByMode && tripStops.length === 0) {

      mapRef.current.flyTo({
        center: [homeLocation.longitude, homeLocation.latitude],
        zoom: 4,
        duration: 1200
      });
    }
  }, [mapRef, homeLocation, isFlyByMode, tripStops]);

  const handleMapClick = (event: MapLayerMouseEvent) => {
    if (isClickable) {
      dispatch(setAddNewSpotMarker({
        location: { latitude: event.lngLat.lat, longitude: event.lngLat.lng },
        color: addNewSpotMarker?.color ?? defaultColor,
        icon: addNewSpotMarker?.icon ?? defaultIcon.icon
      }));
      if (!location.pathname.includes('/add')) {
        navigate(`/trip/${tripId}/stop/add`);
      }
    }
    setSelectedStop(null);
  };

  const handleMarkerClick = (event: MouseEvent, stop: TripStop) => {
    event.stopPropagation()
    setSelectedStop(stop);
    navigate(`/trip/${stop.tripId}/stop/${stop.id}`)
  }

  const handleMapMoveEnd = (event: ViewStateChangeEvent) => {
    dispatch(setMapCenter({ latitude: event.viewState.latitude, longitude: event.viewState.longitude }));
  }

  const markers = useMemo(() => tripStops.map((item, index) => {
    const isSelected = item.id === selectedStopId;
    const markerColor = isSelected && selectedMarkerStyle ? selectedMarkerStyle.color : (item.color ?? defaultColor);
    const markerIcon = isSelected && selectedMarkerStyle ? selectedMarkerStyle.icon : (item.icon ?? icons[0].icon);

    return (
      <Marker
        key={index}
        longitude={item.location.longitude}
        latitude={item.location.latitude}
        anchor="bottom"
      >
        <div className='marker-pin' style={{ background: markerColor }} onClick={(e) => handleMarkerClick(e, item)} >
          <div className='marker-pin-inner' style={{ background: darken(markerColor, 40) }}>
            <MapIcon name={markerIcon} />
          </div>
        </div>
      </Marker>
    );
  }), [tripStops, selectedStopId, selectedMarkerStyle]);

  const homeMarker = useMemo(() => {
    if (!homeLocation) return null;

    return (
      <Marker
        longitude={homeLocation.longitude}
        latitude={homeLocation.latitude}
        anchor="bottom"
      >
        <div className='marker-pin' style={{ background: defaultColor }} onClick={(e) => navigate(`/profile`)} >
          <div className='marker-pin-inner' style={{ background: darken(defaultColor, 40) }}>
            <MapIcon name="PiHouseLine" />
          </div>
        </div>
      </Marker>
    );
  }, [homeLocation]);

  return (
    <div className='map-container'>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: homeLocation?.latitude ?? defaultCenter[0],
          longitude: homeLocation?.longitude ?? defaultCenter[1],
          zoom: homeLocation ? 12 : defaultZoom
        }}
        minZoom={minZoom}
        maxZoom={mapStyle === MapStyle.Satelite ? 15.99 : maxZoom}
        mapStyle={styleSource}
        onClick={handleMapClick}
        onMoveEnd={handleMapMoveEnd}
      >
        {markers}
        {homeMarker}
        {isClickable && addNewSpotMarker?.location && (
          <Marker
            longitude={addNewSpotMarker.location.longitude}
            latitude={addNewSpotMarker.location.latitude}
            anchor="bottom"
          >
            {createSvg(addNewSpotMarker.color ?? defaultColor, addNewSpotMarker.icon ?? defaultIcon.icon)}
          </Marker>
        )}
      </Map>
    </div>
  );
};

export default StopMap;
