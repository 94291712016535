import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { GeocodingResult } from '../../models/GeocodingResult';
import { Location } from "../../models/Location";
import { useLazySearchLocationQuery, useLazyReverseGeocodeQuery } from '../../redux/api/geocodingApi';

interface GeocodingSearchProps {
    placeholder?: string;
    mapCenter?: Location;
    value?: Location;
    allowClear?: boolean;
    onValueChanged: (icon: GeocodingResult) => void;
}

const GeocodingSearch: React.FC<GeocodingSearchProps> = ({ placeholder, mapCenter, value, allowClear = true, onValueChanged }) => {
    const [searchResults, setSearchResults] = useState<GeocodingResult[]>([]);
    const [searchLocation, { isLoading: loadingSearch }] = useLazySearchLocationQuery();
    const [reverseGeocode] = useLazyReverseGeocodeQuery();
    const [displayValue, setDisplayValue] = useState<string>('');

    useEffect(() => {
        const fetchInitialLocation = async () => {
            if (value && value.latitude !== 0 && value.longitude !== 0) {
                try {
                    const results = await reverseGeocode(value).unwrap();
                    if (results.length > 0) {
                        setDisplayValue(results[0].name);
                        setSearchResults([results[0]]);
                    }
                } catch (error) {
                    console.error("Error fetching initial location:", error);
                }
            }
        };

        fetchInitialLocation();
    }, [value, reverseGeocode]);

    const handleSearch = async (searchTerm: string) => {
        if (!searchTerm) {
            setSearchResults([]);
            return;
        }
        try {
            const results = await searchLocation({
                searchQuery: searchTerm,
                location: mapCenter
            }).unwrap();
            setSearchResults(results);
        } catch (error) {
            console.error("Error fetching search results:", error);
            setSearchResults([]);
        }
    };

    const handleSelectLocation = (value: string) => {
        if (value && value?.includes('|')) {
            const [lat, lng] = value.split('|').map(Number);
            const selectedResult = searchResults.find(result =>
                result.location.latitude === lat && result.location.longitude === lng
            );
            if (selectedResult) {
                setDisplayValue(selectedResult.name);
                onValueChanged(selectedResult);
            }
            setSearchResults([]);
        }
    };

    const handleClear = () => {
        setDisplayValue('');
        setSearchResults([]);
        onValueChanged({
            name: '',
            address: '',
            location: { latitude: 0, longitude: 0 }
        });
    };

    return (
        <Select
            showSearch
            allowClear={allowClear}
            value={displayValue}
            notFoundContent={null}
            filterOption={false}
            loading={loadingSearch}
            placeholder={placeholder}
            onSearch={handleSearch}
            onChange={handleSelectLocation}
            onClear={handleClear}
            options={searchResults.map((result) => ({
                label: result.name,
                address: result.address,
                value: `${result.location.latitude}|${result.location.longitude}`,
                key: crypto.randomUUID()
            }))}
            style={{ width: '100%' }}
            optionRender={(option) => (
                <div className='search-result-item'>
                    <b>{option.label}</b>
                    <p>{option.data.address}</p>
                </div>
            )}
        />
    );
};

export default GeocodingSearch;
