import { Dropdown, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import React from 'react';
import { PiArrowLeftBold, PiDotsThreeVerticalBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

interface SidebarHeaderProps {
    title: string;
    backUrl?: string | null;
    dropdown?: ItemType[] | undefined;
    action?: React.ReactNode | null
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ title, backUrl, dropdown = undefined, action = null }) => {
    const navigate = useNavigate();
    const d: MenuProps = {};
    d.items  = dropdown;

    return (
        <div className="sidebar-header">
            <div className="sidebar-header-title">
                {backUrl && (
                    <span className="button" onClick={() => navigate(backUrl)}>
                        <PiArrowLeftBold />
                    </span>
                )}
                <h1 className={backUrl ? 'padded' : ''}>{title}</h1>
            </div>
            <div className="sidebar-header-actions">
                {action}
                {dropdown && dropdown?.length > 0 && (
                    <Dropdown menu={d} trigger={['click']} placement="bottomRight">
                        <span className="button">
                            <PiDotsThreeVerticalBold />
                        </span>
                    </Dropdown>
                )}
            </div>
        </div>
    );
};

export default SidebarHeader;
