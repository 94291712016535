import React from 'react';

interface EmptyProps {
    title: string;
    subtitle: string;
    action?: React.ReactNode;
    imageUrl: string;
}

const Empty: React.FC<EmptyProps> = ({ title, subtitle, action, imageUrl }) => {
    return (
        <div className='empty-container'>
            <div className='empty'>
                <img src={imageUrl} alt='empty' />
                <h1>{title}</h1>
                <p>{subtitle}</p>
                {action}
            </div>
        </div>
    );
};

export default Empty;
