import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { MapStyle } from '../../components/Map/MapStyle';
import StopMap from '../../components/Map/Map';
import Sidebar from '../../components/Sidebar/Sidebar';
import Loader from '../../components/Loader/Loader';
import { useGetTripsQuery } from '../../redux/api/tripsApi';
import { useGetAllTripStopsQuery } from '../../redux/api/tripStopsApi';
import { useGetCurrentUserQuery } from '../../redux/api/userApi';

const MainLayout: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();

  const { isLoading: isTripsLoading } = useGetTripsQuery();
  const { data: tripStops = [], isLoading: isTripStopsLoading } = useGetAllTripStopsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  return (
    <div className="main-page">
      <Sidebar expanded={true}>
        <Loader isLoading={isTripsLoading || isTripStopsLoading} />
        <Outlet />
      </Sidebar>
      <StopMap 
        mapStyle={currentUser?.mapStyle ?? MapStyle.Satelite}
        homeLocation={currentUser?.homeLocation}
        tripStops={tripId ? tripStops.filter(stop => stop.tripId === tripId) : tripStops} />
    </div>
  );
};

export default MainLayout;
