import React from 'react';

interface SidebarProps {
  children: React.ReactNode;
  expanded: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ children, expanded }) => {
  return (
    <div className={`sidebar ${expanded ? 'expanded' : ''}`}>
      {children}
    </div>
  );
};

export default Sidebar;
