import { Trip } from '../../models/Trip';
import { baseApi } from './baseApi';

export const tripsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrips: builder.query<Trip[], void>({
      query: () => '/trips',
      providesTags: ['Trip'],
    }),
    addTrip: builder.mutation<Trip, Partial<Trip>>({
      query: (trip) => ({
        url: '/trips',
        method: 'POST',
        body: trip,
      }),
      invalidatesTags: ['Trip'],
    }),
    updateTrip: builder.mutation<Trip, Trip>({
      query: (trip) => ({
        url: `/trips/${trip.id}`,
        method: 'PUT',
        body: trip,
      }),
      invalidatesTags: ['Trip'],
    }),
    deleteTrip: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trips/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Trip', 'TripStop'],
    }),
  }),
});

export const {
  useGetTripsQuery,
  useAddTripMutation,
  useUpdateTripMutation,
  useDeleteTripMutation,
} = tripsApi; 