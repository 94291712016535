import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { Form, Input, Button, Alert, Select, Col, Row, Modal, MenuProps } from 'antd';
import { User } from '../../models/User';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import { useGetCurrentUserQuery, useUpdateCurrentUserMutation, useDeleteCurrentUserMutation } from '../../redux/api/userApi';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { GeocodingResult } from '../../models/GeocodingResult';
import GeocodingSearch from '../../components/GeocodeSearch/GeocodeSearch';
import { MapStyle } from '../../components/Map/MapStyle';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';

const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  homeLocation: z.object({
    latitude: z.number(),
    longitude: z.number()
  }),
  mapStyle: z.nativeEnum(MapStyle)
});

const EditProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: currentUser, isLoading: isLoadingUser } = useGetCurrentUserQuery();
  const [updateUser, { isLoading: isUpdating }] = useUpdateCurrentUserMutation();
  const [deleteUser] = useDeleteCurrentUserMutation();
  const mapCenter = useSelector((state: RootState) => state.map.center);

  const { control, handleSubmit, formState: { errors }, setValue } = useForm<User>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      homeLocation: {
        latitude: 0,
        longitude: 0
      },
      mapStyle: MapStyle.Road
    },
  });

  useEffect(() => {
    if (currentUser) {
      setValue('firstName', currentUser.firstName);
      setValue('lastName', currentUser.lastName);
      setValue('homeLocation', currentUser.homeLocation);
      setValue('mapStyle', currentUser.mapStyle ?? MapStyle.Road);
    }
  }, [currentUser, setValue]);
  
  function handleGeocodingResult(selectedResult: GeocodingResult): void {
    setValue('homeLocation.latitude', selectedResult.location.latitude);
    setValue('homeLocation.longitude', selectedResult.location.longitude);
  }

  const onSubmit = async (data: User) => {
    try {
      await updateUser(data).unwrap();
      navigate('/');
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleDeleteAccount = () => {
    Modal.confirm({
      title: 'Delete your account',
      content: 'Are you sure you want to delete your account? All your trips and photos will be deleted permanently and CANNOT be recovered. This action CANNOT be undone. ',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteUser().unwrap();
          dispatch(logout());
          navigate('/auth/login');
        } catch (error) {
          console.error('Failed to delete account:', error);
        }
      },
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'email',
      label: <span>Change my email</span>,
    },
    {
      key: 'password',
      label: <span>Change my password</span>,
    },
    {
      key: 'takeout',
      label: <span>Dowload a copy of my data</span>,
    },
    {
      key: 'delete',
      label: <span onClick={handleDeleteAccount}>Delete my account</span>,
      danger: true,
    }
  ];

  return (
    <div>
      <Loader isLoading={isLoadingUser} />
      <SidebarHeader 
        title='Update your profile' 
        backUrl='/' 
        dropdown={items}
      />
      <div className="sidebar-content">
        {!currentUser && (
          <Alert
            message="Profile not found"
            description="Your profile information could not be found."
            type="error"
            showIcon
          />
        )}
        {currentUser && (
          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <Form.Item
              label="Your name"
              validateStatus={errors.firstName ? 'error' : ''}
              help={errors.firstName ? errors.firstName.message : ''}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => <Input {...field} placeholder='John' />}
                            />
                        </Col>
                        <Col span={12}>
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => <Input {...field} placeholder='Doe' />}
                            />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label="Home location"
              validateStatus={errors.homeLocation ? 'error' : ''}
              help={errors.homeLocation ? errors.homeLocation.message : ''}
              tooltip='Adding your address is optional, but it helps us to personalize your experience.'
            >
              <GeocodingSearch
                placeholder='Enter your address (e.g., "123 Main Street, San Francisco")'
                mapCenter={mapCenter}
                value={currentUser.homeLocation}
                onValueChanged={handleGeocodingResult}
              />
            </Form.Item>

            <Form.Item
              label="Map Style"
              validateStatus={errors.mapStyle ? 'error' : ''}
              help={errors.mapStyle ? errors.mapStyle.message : ''}
              tooltip='Choose your preferred map style'
            >
              <Controller
                name="mapStyle"
                control={control}
                render={({ field }) => (
                  <Select 
                    {...field} 
                    placeholder='Select map style'
                    options={[
                      { value: MapStyle.Road, label: 'Road' },
                      { value: MapStyle.Satelite, label: 'Satellite' }
                    ]}
                  />
                )}
              />
            </Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                {isUpdating ? 'Updating...' : 'Update'}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default EditProfilePage; 