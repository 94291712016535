import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Button } from 'antd';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='auth'>
            <div className='auth_background'>
                <img src='/logo_white.png' alt='wonderly' />
            </div>
            <div className='auth_content'>
                <div>
                <h1 style={{
                    marginBottom: '.5rem'
                }}>Oops!</h1>
                <h2 style={{
                    marginBottom:'1rem'
                }}>We couldn't find what you were looking for</h2>
                <Button onClick={() => navigate('/')}>Go back to the app</Button>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
