import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTripsQuery } from '../../redux/api/tripsApi';
import { HiOutlinePlus } from "react-icons/hi";
import dayjs from 'dayjs';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import SidebarItem from '../../components/Sidebar/SidebarItem';
import Loader from '../../components/Loader/Loader';
import { Button } from 'antd';
import Empty from '../../components/Empty/Empty';

const TripOverviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: trips = [], isLoading } = useGetTripsQuery();

  return (
    <div>
      <Loader isLoading={isLoading} />
      <SidebarHeader
        title='My trips'
        backUrl='/'
        action={trips.length > 0 && (<span className="button" onClick={() => navigate('/trips/add')}><HiOutlinePlus /></span>)}
      />
      <div className="sidebar-content">
        {trips.map((trip) => (
          <SidebarItem
            key={trip.id}
            expanded={true}
            title={trip.name}
            subtitle={trip.startUtc && trip.endUtc &&
              `${trip.startUtc ? dayjs(trip.startUtc).format('DD/MM/YYYY') : ''} \u00a0|\u00a0 ${trip.endUtc ? dayjs(trip.endUtc).format('DD/MM/YYYY') : ''}`}
            backgroundUrl={trip.photoUrl}
            onClick={() => navigate(`/trip/${trip.id}`)}
          />
        ))}
        {trips.length === 0 && (
          <Empty
            title='Your first adventure'
            subtitle="Let's get you started on your journey. Click the button below to create your first trip."
            imageUrl='/no-trips.svg'
            action={<Button type='primary' onClick={() => navigate('/trips/add')}>Get started</Button>}
          />
        )}
      </div>
    </div>
  );
};

export default TripOverviewPage;
