import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { Form, Input, Button, DatePicker, Alert } from 'antd';
import { Trip } from '../../models/Trip';
import dayjs, { Dayjs } from 'dayjs';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import { useUpdateTripMutation } from '../../redux/api/tripsApi';
import { useGetTripsQuery } from '../../redux/api/tripsApi';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const schema = z.object({
    id: z.string().min(1, "ID is required"),
    name: z.string().min(1, "Trip name is required"),
    description: z.string().min(1, "Description is required"),
    startUtc: z.instanceof(dayjs as unknown as typeof Dayjs).nullable(),
    endUtc: z.instanceof(dayjs as unknown as typeof Dayjs).nullable(),
});

const EditTripPage: React.FC = () => {
    const navigate = useNavigate();
    const { tripId } = useParams<{ tripId: string }>();
    const { data: trips = [] } = useGetTripsQuery();
    const [updateTrip, { isLoading }] = useUpdateTripMutation();
    const trip = trips.find(t => t.id === tripId);

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<Trip>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: '',
            startUtc: null,
            endUtc: null,
        },
    });

    useEffect(() => {
        if (trip && tripId) {
            setValue('id', trip.id)
            setValue('name', trip.name);
            setValue('description', trip.description);
            if (trip.startUtc && trip.endUtc) {
                setValue('startUtc', dayjs(trip.startUtc));
                setValue('endUtc', dayjs(trip.endUtc));
            }
        }
    }, [trip, tripId, setValue]);

    const onSubmit = async (data: Trip) => {
        try {
            await updateTrip(data).unwrap();
            navigate(`/trip/${tripId}`);
        } catch (err) {
            console.error('Failed to update trip:', err);
        }
    };

    function handleDateSelect(dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]): void {
        if (dates) {
            setValue('startUtc', dates[0] as Dayjs);
            setValue('endUtc', dates[1] as Dayjs);
        }
    }

    return (
        <div>
            <SidebarHeader title='Update trip' backUrl={trip ? `/trip/${tripId}` : '/trips'} dropdown={[]} />
            <div className="sidebar-content">
                {!trip && (
                    <Alert
                        message="Trip not found"
                        description="The trip you're trying to update could not be found."
                        type="error"
                        showIcon
                    />
                )}
                {trip && (
                    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                        <Form.Item
                            label="Name"
                            validateStatus={errors.name ? 'error' : ''}
                            help={errors.name ? errors.name.message : ''}
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input {...field} placeholder='Ex. Road Trip through California" or "Backpacking in Europe"' />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            validateStatus={errors.description ? 'error' : ''}
                            help={errors.description ? errors.description.message : ''}
                        >
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => <TextArea rows={4} {...field} placeholder='Write a short description, e.g., "Exploring the scenic routes and vibrant cities."' />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Duration"
                            validateStatus={errors.startUtc ? 'error' : ''}
                            help={errors.startUtc ? errors.startUtc.message : ''}
                        >
                            <Controller
                                name="startUtc"
                                control={control}
                                render={({ field }) => (
                                    <RangePicker
                                        {...field}
                                        style={{ width: '100%' }}
                                        value={[getValues('startUtc'), getValues('endUtc')]}
                                        onChange={(dates, dateStrings) => handleDateSelect(dates ? [dates[0]!, dates[1]!] : null, dateStrings)} />
                                )}
                            />
                        </Form.Item>

                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                {isLoading ? 'Updating...' : 'Update Trip'}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default EditTripPage;