import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TripStop } from '../../models/TripStop';
import { Button, Col, DatePicker, Form, Input, Row, Alert } from 'antd';
import * as z from 'zod';
import IconPicker from '../../components/IconPicker/IconPicker';
import dayjs, { Dayjs } from 'dayjs'
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import { defaultIcon } from '../../utils/icons';
import { defaultColor } from '../../utils/color';
import { useUpdateTripStopMutation } from '../../redux/api/tripStopsApi';
import { useGetAllTripStopsQuery } from '../../redux/api/tripStopsApi';
import { setSelectedStopId } from '../../redux/slices/tripStopSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { setSelectedMarkerStyle } from '../../redux/slices/mapSlice';

const schema = z.object({
    id: z.string().min(1, "ID is required"),
    name: z.string().min(1, 'Place pick a name'),
    description: z.string().nullable(),
    color: z.string().nullable(),
    icon: z.string().nullable(),
    arrivalUtc: z.instanceof(dayjs as unknown as typeof Dayjs, { message: "Please select an arrival date" }),
    location: z.object({
        latitude: z.number().refine(val => val !== 0, {
            message: 'Please select a location on the map',
        }),
        longitude: z.number().refine(val => val !== 0, {
            message: 'Please select a location on the map',
        }),
    })
});

const EditTripStopPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { tripId, stopId } = useParams<{ tripId: string; stopId: string }>();
    
    const { data: tripStops = [] } = useGetAllTripStopsQuery();
    const [updateTripStop, { isLoading }] = useUpdateTripStopMutation();
    const tripStop = tripStops.find(stop => stop.id === stopId);

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<TripStop>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: '',
            description: null,
            icon: defaultIcon.icon,
            color: defaultColor,
            arrivalUtc: dayjs(),
            location: { latitude: 0, longitude: 0 },
        },
    });

    useEffect(() => {
        if (stopId) {
            dispatch(setSelectedStopId(stopId));
            dispatch(setSelectedMarkerStyle(null));
        }
        return () => {
            dispatch(setSelectedStopId(null));
            dispatch(setSelectedMarkerStyle(null));
        };
    }, [dispatch, stopId]);

    useEffect(() => {
        if (tripStop) {
            setValue('id', tripStop.id);
            setValue('name', tripStop.name);
            setValue('description', tripStop.description);
            setValue('icon', tripStop.icon);
            setValue('color', tripStop.color);
            setValue('arrivalUtc', dayjs(tripStop.arrivalUtc));
            setValue('location', tripStop.location);
        }
    }, [tripStop, setValue]);

    const onSubmit = async (data: TripStop) => {
        try {
            await updateTripStop({ ...data, tripId: tripId! }).unwrap();
            navigate(`/trip/${tripId}/stop/${stopId}`);
        } catch (err) {
            console.error('Failed to update trip stop:', err);
        }
    };

    const handleIconSelect = (icon: string) => {
        setValue('icon', icon);
        dispatch(setSelectedMarkerStyle({
            location: tripStop!.location,
            color: getValues('color')!,
            icon: icon
        }));
    };

    const handleColorSelect = (color: string) => {
        setValue('color', color);
        dispatch(setSelectedMarkerStyle({
            location: tripStop!.location,
            color: color,
            icon: getValues('icon')!
        }));
    };

    function handleDateSelect(date: Dayjs, dateString: string | string[]): void {
        setValue('arrivalUtc', date);
    }

    return (
        <div>
            <SidebarHeader title='Update trip stop' backUrl={`/trip/${tripId}/stop/${stopId}`} />
            <div className="sidebar-content">
                {!tripStop && (
                    <Alert
                        message="Trip stop not found"
                        description="The trip stop you're trying to update could not be found."
                        type="error"
                    />
                )}
                {tripStop && (
                    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                        <Form.Item
                            label="Name"
                            validateStatus={errors.name ? 'error' : ''}
                            help={errors.name ? errors.name.message : ''}
                        >
                            <Row wrap={false}>
                                <Col flex="none" style={{ paddingRight: '.5rem' }}>
                                    <Controller
                                        name="icon"
                                        control={control}
                                        render={({ field }) => (
                                            <IconPicker
                                                initialIcon={field.value!}
                                                initialColor={tripStop?.color!}
                                                onIconSelect={handleIconSelect}
                                                onColorSelect={handleColorSelect}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => <Input {...field} placeholder='Ex. Golden Gate Bridge, Yosemite National Park' />}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="Arrival date"
                            validateStatus={errors.arrivalUtc ? 'error' : ''}
                            help={errors.arrivalUtc ? errors.arrivalUtc.message : ''}
                        >
                            <Controller
                                name="arrivalUtc"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        style={{ width: '100%' }}
                                        value={field.value || null}
                                        onChange={handleDateSelect} />
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            validateStatus={errors.description ? 'error' : ''}
                            help={errors.description ? errors.description.message : ''}
                        >
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <Input.TextArea
                                        rows={4}
                                        {...field}
                                        value={field.value || ''}
                                        placeholder='Describe this stop, e.g., "Iconic landmark with great views."'
                                    />
                                )}
                            />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                {isLoading ? 'Updating...' : 'Update stop'}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default EditTripStopPage;
