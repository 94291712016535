import React, { useState, useEffect } from 'react';
import { Popover, Button, Row, Col, Input } from 'antd';
import * as PiIcons from 'react-icons/pi';
import { red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, gray } from '@ant-design/colors';
import { icons } from '../../utils/icons';
import { isDark } from '../../utils/color';

type IconName = keyof typeof PiIcons;

const genPresets = (presets = { red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, gray }) => {
  const allColors = Object.values({
    red: red.slice(2),
    volcano: volcano.slice(2),
    orange: orange.slice(2),
    gold: gold.slice(2),
    yellow: yellow.slice(2),
    lime: lime.slice(2),
    green: green.slice(2),
    cyan: cyan.slice(2),
    blue: blue.slice(2),
    geekblue: geekblue.slice(2),
    purple: purple.slice(2),
    magenta: magenta.slice(2),
    gray: gray.slice(2), 
  }).flat();

  return [{
    label: 'Colors',
    colors: allColors
  }];
};

interface IconPickerProps {
  initialIcon: string; 
  initialColor: string;
  onIconSelect?: (icon: string) => void;
  onColorSelect?: (color: string) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({ initialIcon, initialColor, onIconSelect, onColorSelect }) => {
  const [selectedIcon, setSelectedIcon] = useState<IconName>(initialIcon as IconName);
  const [selectedColor, setSelectedColor] = useState<string | undefined>(initialColor);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedIcon(initialIcon as IconName);
  }, [initialIcon]);

  const handleIconSelect = (icon: string) => {
    const iconName = icon as IconName;
    setSelectedIcon(iconName);
    if (onIconSelect) {
      onIconSelect(icon);
    }
  };

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
    if (onColorSelect) {
      onColorSelect(color);
    }
  };

  const handleOpenChange = (visible: boolean) => {
    setIsOpen(visible);
  };

  const filteredIcons = icons.filter((icon) =>
    icon.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
    icon.keywords.some((keyword) => keyword.toLowerCase().includes(searchQuery.toLowerCase()))
  ).sort((a, b) => a.category.localeCompare(b.category));

  const renderIcons = () => {
    return filteredIcons.map((icon) => {
      const IconComponent = PiIcons[icon.icon as IconName] as React.ComponentType;
      return (
        <Col span={4} key={icon.icon}>
          <Button
            size='middle'
            type={selectedIcon === icon.icon ? 'primary' : 'default'}
            onClick={() => handleIconSelect(icon.icon)}
            icon={
              <span style={{ fontSize: 22 }}>
                <IconComponent />
              </span>
            }
          />
        </Col>
      );
    });
  };

  const renderPresets = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {genPresets()[0].colors.map((color) => (
          <div
            key={color}
            onClick={() => handleColorSelect(color)}
            style={{
              width: 24,
              height: 24,
              margin: 3,
              borderRadius: '.2rem',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                backgroundColor: color,
                width: 24,
                height: 24,
                borderRadius: '.2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {selectedColor === color && (
                <PiIcons.PiCheck
                  style={{
                    position: 'absolute',
                    color: isDark(color) ? '#fff' : '#000',
                    fontSize: 21,
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const popoverContent = (
    <>
      <Row gutter={[8, 8]} style={{ width: 500 }}>
        <Col span={12} >
          <Input
            placeholder="Search by category or keyword"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Row gutter={[8, 8]} style={{ maxHeight: 340, overflow: 'auto' }}>
            {renderIcons()}
          </Row>
        </Col>
        <Col span={12}>
          {renderPresets()}
        </Col>
      </Row>
    </>
  );

  const SelectedIconComponent = PiIcons[selectedIcon];

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99, 
            cursor: 'default',
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
      <div style={{ position: 'relative', zIndex: 1000 }}>
        <Popover 
          content={popoverContent} 
          trigger="click"
          open={isOpen}
          onOpenChange={handleOpenChange}
          destroyTooltipOnHide
        >
          <div className='icon-picker-btn' style={{backgroundColor: selectedColor}}>
              {<SelectedIconComponent style={{ color: isDark(selectedColor)? '#fff' : '#000', fontSize: 26 }} />}
          </div>
        </Popover>
      </div>
    </>
  );
};

export default IconPicker;
