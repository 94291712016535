import './App.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import PrivateRoute from './components/PrivateRoute';
import NotFoundPage from './pages/NotFoundPage';
import MainLayout from './pages/layouts/MainLayout';
import AuthLayout from './pages/layouts/AuthLayout';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import AddTripPage from './pages/trips/AddTripPage';
import EditTripPage from './pages/trips/EditTripPage';
import DetailTripPage from './pages/trips/DetailTripPage';
import TripOverviewPage from './pages/trips/TripOverviewPage';
import AddTripStopPage from './pages/tripStops/AddTripStopPage';
import EditTripStopPage from './pages/tripStops/EditTripStopPage';
import TripStopDetailPage from './pages/tripStops/TripStopDetailsPage';
import HomePage from './pages/home/HomePage';
import EditProfilePage from './pages/profile/EditProfilePage';
import OnboardingPage from './pages/auth/OnboardingPage';

const App: React.FC = () => {
  return (
    <ConfigProvider
    componentSize='large' 
      theme={{
        token: {
          colorPrimary: '#4096ff'
        },
      }}
    >
      <Router>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="onboarding" element={<OnboardingPage />} />
          </Route>
          <Route path="/" element={<PrivateRoute element={<MainLayout />} />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute element={<MainLayout />} />}>
            <Route index element={<EditProfilePage />} />
          </Route>
          <Route path="/trips" element={<PrivateRoute element={<MainLayout />} />}>
            <Route index element={<TripOverviewPage />} />
            <Route path="add" element={<AddTripPage />} />
          </Route>
          <Route path="/trip" element={<PrivateRoute element={<MainLayout />} />}>
            <Route path=":tripId" element={<DetailTripPage />} />
            <Route path=":tripId/edit" element={<EditTripPage />} />
            <Route path=":tripId/stop/:stopId" element={<TripStopDetailPage />} />
            <Route path=":tripId/stop/:stopId/edit" element={<EditTripStopPage />} />
            <Route path=":tripId/stop/add" element={<AddTripStopPage />} />
          </Route>
          <Route path="*" element={<PrivateRoute element={<NotFoundPage />} />}>
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>

  );
};

export default App;
