import React, { MouseEventHandler } from 'react';

interface SidebarItemProps {
    key: string;
    title: string;
    subtitle?: string | null;
    backgroundUrl?: string | null;
    expanded?: boolean | null;
    onClick: MouseEventHandler;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ onClick, title, subtitle = null, backgroundUrl = null, expanded = null }) => {
    return (
        <div className={`sidebar-item ${expanded && 'expanded'}`}  onClick={onClick} >
            <div className='sidebar-item-container'>
                <img src={backgroundUrl ?? '/placeholder.jpg'} alt={title} />
                <div className='sidebar-item-overlay'></div>
            </div>
            <div className='sidebar-item-content'>
                <h2 className="sidebar-item-title">{title}</h2>
                {subtitle && (
                    <h3 className="sidebar-item-subtitle">{subtitle}</h3>
                )}
            </div>
        </div>
    );
};

export default SidebarItem;
