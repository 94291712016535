export const darken = (hex: string, percent: number): string => {
  // Ensure the hex color is in the correct format
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  // Parse the hex color into RGB components
  let R: number = parseInt(hex.substring(0, 2), 16);
  let G: number = parseInt(hex.substring(2, 4), 16);
  let B: number = parseInt(hex.substring(4, 6), 16);

  // Calculate the darkened color
  R = Math.round(R * (1 - percent / 100));
  G = Math.round(G * (1 - percent / 100));
  B = Math.round(B * (1 - percent / 100));

  // Ensure the values are within the valid range
  R = Math.max(0, Math.min(255, R));
  G = Math.max(0, Math.min(255, G));
  B = Math.max(0, Math.min(255, B));

  // Convert the RGB values back to a hex string
  const RR: string = R.toString(16).padStart(2, '0');
  const GG: string = G.toString(16).padStart(2, '0');
  const BB: string = B.toString(16).padStart(2, '0');

  return `#${RR}${GG}${BB}`;
}

export const isDark = (bgColor: string | undefined) => {
  if(bgColor === undefined) {
    return false;
  }

  let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  let r = parseInt(color.substring(0, 2), 16); // hexToR
  let g = parseInt(color.substring(2, 4), 16); // hexToG
  let b = parseInt(color.substring(4, 6), 16); // hexToB
  return ((r * 0.299) + (g * 0.587) + (b * 0.114)) <= 195;
}

export const defaultColor: string = '#4096ff';