import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { defaultColor } from '../../utils/color';

interface LoaderProps {
    isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
    return (
        <div className={`loader ${isLoading ? 'loading' : ''}`}>
            <LoadingOutlined style={{
                fontSize: '3rem',
                fontWeight: '600',
                color: defaultColor
            }} />
        </div>
    );
};

export default Loader;
