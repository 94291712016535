import React from 'react';
import * as PiIcons from 'react-icons/pi';

type IconName = keyof typeof PiIcons;

interface MapIconProps {
    name: string
}

const MapIcon: React.FC<MapIconProps> = ({ name }) => {
    const IconComponent = PiIcons[name as IconName] as React.ComponentType;

    return (
        <IconComponent />
    )
};

export default MapIcon;
