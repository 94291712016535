import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useGetCurrentUserQuery } from '../redux/api/userApi';

const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (isAuthenticated && currentUser && !currentUser.firstName) {
    return <Navigate to="/auth/onboarding" />;
  }

  return element;
};

export default PrivateRoute;