import { useNavigate } from 'react-router-dom';
import React from 'react';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import SidebarItem from '../../components/Sidebar/SidebarItem';
import { useGetCurrentUserQuery } from '../../redux/api/userApi';
import Loader from '../../components/Loader/Loader';
import { MenuProps } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { logout } from '../../redux/slices/authSlice';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { data: user, isLoading } = useGetCurrentUserQuery();

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (<span onClick={() => dispatch(logout())}>Sign out</span>),
    }
  ];

  return (
    <div>
      <Loader isLoading={isLoading} />

      <SidebarHeader title={`Hello ${user?.firstName ?? 'traveler'}!`} dropdown={items} />
      <div className="sidebar-content">
        <div>
          <SidebarItem
            key='my-trip'
            expanded={true}
            title='Trips'
            subtitle='see your travel memories and add new ones'
            backgroundUrl='/mytrip.jpg'
            onClick={() => navigate(`/trips`)}
          />
        </div>
        <div>
          <SidebarItem
            key='my-trip'
            expanded={true}
            title='Photos'
            backgroundUrl='/photos.jpg'
            subtitle='view your photo gallery'
            onClick={() => navigate(`/photos`)}
          />
        </div>
        <div style={{
          display: 'flex',
          gap: '1.15rem'
        }}>
          <SidebarItem
            key='a-year-ago'
            expanded={true}
            title='A year ago'
            subtitle='you visited Brno'
            backgroundUrl='/brno-placeholder.webp'
            onClick={() => navigate(`/`)}
          />
          <SidebarItem
            key='trip-planing'
            expanded={true}
            title='Travel guides'
            subtitle='Plan your next trip'
            backgroundUrl='/travel-guides.jpg'
            onClick={() => navigate(`/`)}
          />
        </div>
        <div style={{
          display: 'flex',
          gap: '1.15rem'
        }}>
          <SidebarItem
            key='stats'
            expanded={true}
            title='Statistics'
            subtitle="see which places you've visited"
            backgroundUrl='/visited-place.jpg'
            onClick={() => navigate(`/`)}
          />
          <SidebarItem
            key='profile'
            expanded={true}
            title='Profile'
            subtitle="Update your settings"
            backgroundUrl='/profile.jpg'
            onClick={() => navigate(`/profile`)}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
