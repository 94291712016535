import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { TripStop } from '../../models/TripStop';
import { MenuProps, Alert, Modal } from 'antd';
import { setFlyByMode, setMapClickable } from '../../redux/slices/mapSlice';
import dayjs from 'dayjs'
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import SidebarItem from '../../components/Sidebar/SidebarItem';
import { useGetTripsQuery, useDeleteTripMutation } from '../../redux/api/tripsApi';
import { useGetAllTripStopsQuery } from '../../redux/api/tripStopsApi';

const DetailTripPage: React.FC = () => {
  const { tripId } = useParams<{ tripId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { data: trips = [] } = useGetTripsQuery();
  const { data: tripStops = [] } = useGetAllTripStopsQuery();
  const [deleteTrip, { error: deleteError }] = useDeleteTripMutation();
  
  const trip = trips.find(t => t.id === tripId);
  const filteredTripStops = tripStops.filter(stop => stop.tripId === tripId);
  const isFlyByMode = useSelector((state: RootState) => state.map.isFlyByMode);

  useEffect(() => {
    dispatch(setFlyByMode(false));
    dispatch(setMapClickable(true));
    return () => {
      dispatch(setMapClickable(false));
      dispatch(setFlyByMode(false));
    };
  }, [dispatch]);
  
  const handleStopClick = (stopId: string) => {
    navigate(`/trip/${tripId}/stop/${stopId}`);
  };

  const handleDeleteTrip = async () => {
    Modal.confirm({
      title: 'Delete Trip',
      content: 'Are you sure you want to delete this trip? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        if (trip) {
          try {
            await deleteTrip(trip.id).unwrap();
            navigate('/trips');
          } catch (err) {
            console.error('Failed to delete trip:', err);
          }
        }
      },
    });
  };

  const handleTripPlay = () => {
    dispatch(setFlyByMode(!isFlyByMode));
  }

  const items: MenuProps['items'] = !trip ? [] :
  [
    {
      key: '0',
      label: (<span onClick={() => handleTripPlay()}>{!isFlyByMode ? 'Play this trip' : 'Stop replaying this trip'}</span>),
    },
    {
      key: '1',
      label: (<span onClick={() => navigate(`/trip/${tripId}/edit`)}>Update this trip</span>),
    },
    {
      key: '2',
      label: (<span onClick={() => handleDeleteTrip()}>Delete this trip</span>),
    }
  ];

  return (
    <div>
      <SidebarHeader title={trip ? trip.name : 'Trip'} backUrl='/trips' dropdown={items} />
      <div className="sidebar-content">
        {!trip && (
          <Alert
            message="Trip not found"
            description="The trip you're trying to access could not be found."
            type="error"
          />
        )}
        {deleteError && (
          <Alert
            message="Error deleting trip"
            description={`Failed to delete trip: ${(deleteError as any).data?.message || 'Unknown error occurred'}`}
            type="error"
          />
        )}
        {trip && (
          <>
            {filteredTripStops.map((stop: TripStop) => (
              <SidebarItem
                key={stop.id}
                title={stop.name}
                subtitle={dayjs(stop.arrivalUtc).format('DD/MM/YYYY')}
                backgroundUrl={stop.photos?.[0]?.url}
                onClick={() => handleStopClick(stop.id)}
              />
            ))}
            <SidebarItem
              key='add'
              title='Add a new stop to your trip'
              subtitle='Save memories from your trip'
              onClick={() => navigate(`/trip/${tripId}/stop/add`)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DetailTripPage;
