import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TripStopState {
  selectedStopId: string | null;
}

const initialState: TripStopState = {
  selectedStopId: null,
};

const tripStopSlice = createSlice({
  name: 'tripStops',
  initialState,
  reducers: {
    setSelectedStopId: (state, action: PayloadAction<string | null>) => {
      state.selectedStopId = action.payload;
    },
  },
});

export const { setSelectedStopId } = tripStopSlice.actions;
export default tripStopSlice.reducer;
