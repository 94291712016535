import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { Alert, Divider, MenuProps, Modal, Space } from 'antd';
import { Image } from 'antd';
import PhotoUpload from '../../components/PhotoUpload';
import dayjs from 'dayjs'
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import { useGetAllTripStopsQuery, useDeleteTripStopMutation, useAddTripStopPhotoMutation, useDeleteTripStopPhotoMutation } from '../../redux/api/tripStopsApi';
import { setSelectedStopId } from '../../redux/slices/tripStopSlice';
import { TripStopPhoto } from '../../models/TripStopPhoto';
import { PiArrowArcLeft, PiArrowArcRight, PiArrowLeft, PiArrowRight, PiArrowsDownUp, PiArrowsLeftRight, PiClock, PiMagnifyingGlassMinus, PiMagnifyingGlassPlus, PiMapPin, PiPencil, PiTrash } from 'react-icons/pi';

const TripStopDetailPage: React.FC = () => {
    const { tripId, stopId } = useParams<{ tripId: string; stopId: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [current, setCurrent] = React.useState(0);
    const [previewOpen, setPreviewOpen] = useState(false);
    const { data: tripStops = [] } = useGetAllTripStopsQuery();
    const [deleteTripStop, { error: deleteError }] = useDeleteTripStopMutation();
    const [addTripStopPhoto] = useAddTripStopPhotoMutation();
    const [deleteTripStopPhoto] = useDeleteTripStopPhotoMutation();

    const tripStop = tripStops.find(stop => stop.id === stopId);

    const [uploadStatus, setUploadStatus] = React.useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
    const [uploadError, setUploadError] = React.useState<string | null>(null);

    useEffect(() => {
        if (stopId) {
            dispatch(setSelectedStopId(stopId));
        }
        return () => {
            dispatch(setSelectedStopId(null));
        };
    }, [dispatch, stopId]);

    const handleDeleteStop = async () => {
        Modal.confirm({
            title: 'Delete Stop',
            content: 'Are you sure you want to delete this stop? This action cannot be undone.',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                if (stopId) {
                    try {
                        await deleteTripStop(stopId).unwrap();
                        navigate(`/trip/${tripId}`);
                    } catch (error) {
                        console.error('Failed to delete stop:', error);
                    }
                }
            },
        });
    };

    const handlePhotoUploadSuccess = async (photoId: string) => {
        if (stopId) {
            try {
                setUploadStatus('uploading');
                await addTripStopPhoto({ tripStopId: stopId, fileId: photoId }).unwrap();
                setUploadStatus('success');
                setTimeout(() => setUploadStatus('idle'), 3000);
            } catch (error) {
                setUploadStatus('error');
                setUploadError('Failed to upload photo');
                console.error('Failed to upload photo:', error);
            }
        }
    };

    const handlePhotoUploadError = (error: string) => {
        setUploadStatus('error');
        setUploadError('Failed to upload photo');
        console.error('Failed to upload photo:', error);
    };

    const items: MenuProps['items'] = !tripStop ? [] :
        [
            {
                key: '1',
                label: (<span onClick={() => navigate(`/trip/${tripId}/stop/${stopId}/edit`)}>Update stop</span>),
            },
            {
                key: '2',
                label: (<span onClick={handleDeleteStop}>Delete stop</span>),
            },
        ];

    function onDelete(event: React.MouseEvent<SVGElement, MouseEvent>, previewUrl: string): void {
        event.stopPropagation();
        
        if (!tripStop?.photos?.length) return;
        
        const photoToDelete = tripStop.photos.find(photo => photo.url === previewUrl);
        if (!photoToDelete) return;

        Modal.confirm({
            title: 'Delete Photo',
            content: 'Are you sure you want to delete this photo? This action cannot be undone.',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await deleteTripStopPhoto({ 
                        tripStopId: stopId!, 
                        fileId: photoToDelete.id 
                    }).unwrap();
                    
                    if (tripStop.photos) {
                        if (tripStop.photos.length <= 1) {
                            setPreviewOpen(false);
                            return;
                        }

                        const currentIndex = tripStop.photos.findIndex(p => p.url === previewUrl);
                        if (currentIndex === tripStop.photos.length - 1) {
                            setCurrent(Math.max(0, currentIndex - 1));
                        } else {
                            setCurrent(currentIndex);
                        }
                    }
                } catch (error) {
                    console.error('Failed to delete photo:', error);
                }
            },
        });
    }

    return (
        <div>
            <SidebarHeader title={tripStop ? tripStop.name : 'Stop'} backUrl={`/trip/${tripId}`} dropdown={items} />
            <div className="sidebar-content">
                <Space direction="vertical" style={{ width: '100%' }}>
                    {!tripStop && (
                        <Alert
                            message="Stop not found"
                            description="The trip stop you're trying to access could not be found."
                            type="error"
                        />
                    )}
                    {deleteError && (
                        <Alert
                            message="Error deleting stop"
                            description={`Failed to delete stop: ${(deleteError as any).data?.message || 'Unknown error occurred'}`}
                            type="error"
                            closable
                        />
                    )}
                    {tripStop && (
                        <>
                            <p>
                                <PiClock /> {tripStop?.arrivalUtc ? dayjs(tripStop.arrivalUtc).format('dddd, MMMM D   YYYY') : 'No date available.'}
                            </p>
                            <p>
                                <PiMapPin /> {tripStop?.name ?? 'No name available.'}
                            </p>
                            
                            <p>
                                <PiPencil /> {tripStop?.description ? tripStop.description : ''}
                            </p>

                            {uploadStatus === 'uploading' && (
                                <Alert message="Upload in progress" type="info" />
                            )}
                            {uploadStatus === 'success' && (
                                <Alert message="Upload successful" type="success" />
                            )}
                            {uploadStatus === 'error' && (
                                <Alert message={uploadError || 'Upload failed'} type="error" closable onClose={() => setUploadStatus('idle')} />
                            )}

                            <PhotoUpload onUploadSuccess={handlePhotoUploadSuccess} onUploadError={handlePhotoUploadError} />
                            <Image.PreviewGroup
                                preview={{
                                    onVisibleChange: (visible) => {
                                        setPreviewOpen(visible);
                                        if (!visible) setCurrent(0);
                                    },
                                    current,
                                    toolbarRender: (
                                        _,
                                        {
                                            transform: { scale },
                                            current: previewCurrent,
                                            actions: {
                                                onActive,
                                                onFlipY,
                                                onFlipX,
                                                onRotateLeft,
                                                onRotateRight,
                                                onZoomOut,
                                                onZoomIn,
                                                onReset,
                                            },
                                        },
                                    ) => {
                                        const currentPhotoUrl = tripStop?.photos?.[previewCurrent]?.url;
                                        return (
                                            <Space size={24} className="gallery-toolbar">
                                                <PiArrowLeft onClick={() => onActive?.(-1)} />
                                                <PiArrowRight onClick={() => onActive?.(1)} />
                                                <Divider type="vertical" />
                                                <PiArrowsDownUp onClick={onFlipY} />
                                                <PiArrowsLeftRight onClick={onFlipX} />
                                                <Divider type="vertical" />
                                                <PiArrowArcLeft onClick={onRotateLeft} />
                                                <PiArrowArcRight onClick={onRotateRight} />
                                                <Divider type="vertical" />
                                                <PiMagnifyingGlassMinus onClick={onZoomOut} />
                                                <PiMagnifyingGlassPlus onClick={onZoomIn} />
                                                <Divider type="vertical" />
                                                <PiTrash onClick={(e) => currentPhotoUrl && onDelete(e, currentPhotoUrl)} />
                                            </Space>
                                        );
                                    },
                                    onChange: (index) => {
                                        setCurrent(index);
                                    }
                                }}>
                                {(tripStop?.photos ?? []).map((photo: TripStopPhoto, index) => (
                                    <Image 
                                        key={photo.id} 
                                        width="100%" 
                                        src={photo.url} 
                                        fallback="/image_failed.jpg"
                                        onClick={() => setCurrent(index)}
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </>
                    )}
                </Space>
            </div>
        </div >
    );
};

export default TripStopDetailPage;
