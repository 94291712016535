import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddTripMutation } from '../../redux/api/tripsApi';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { Form, Input, Button, DatePicker } from 'antd';
import { Trip } from '../../models/Trip';
import dayjs, { Dayjs } from 'dayjs';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import Loader from '../../components/Loader/Loader';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const schema = z.object({
  name: z.string().min(1, "Trip name is required"),
  description: z.string().min(1, "Description is required"),
  startUtc: z.instanceof(dayjs as unknown as typeof Dayjs).nullable(),
  endUtc: z.instanceof(dayjs as unknown as typeof Dayjs).nullable(),
});

const AddTripPage: React.FC = () => {
  const navigate = useNavigate();
  const [addTrip, { isLoading }] = useAddTripMutation();

  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<Trip>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      startUtc: null,
      endUtc: null,
    },
  });

  const onSubmit = async (data: Trip) => {
    try {
      const newTrip = await addTrip(data).unwrap();
      navigate(`/trip/${newTrip.id}`);
    } catch (err) {
      console.error('Failed to add trip:', err);
    }
  };

  function handleDateSelect(dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]): void {
    if (dates) {
      setValue('startUtc', dates![0] as Dayjs);
      setValue('endUtc', dates![1] as Dayjs);
    }
  }

  return (
    <div>
      <SidebarHeader title='Add a new trip' backUrl='/trips' />
      <Loader isLoading={isLoading} />

      <div className="sidebar-content">
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Form.Item
            label="Name"
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name ? errors.name.message : ''}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input {...field} placeholder='Ex. Road Trip through California" or "Backpacking in Europe"' />}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            validateStatus={errors.description ? 'error' : ''}
            help={errors.description ? errors.description.message : ''}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextArea rows={4} {...field} placeholder='Write a short description, e.g., "Exploring the scenic routes and vibrant cities."' />}
            />
          </Form.Item>

          <Form.Item
            label="Duration"
            validateStatus={errors.startUtc ? 'error' : ''}
            help={errors.startUtc ? errors.startUtc.message : ''}
          >
            <Controller
              name="startUtc"
              control={control}
              render={({ field }) => (
                <RangePicker
                  {...field}
                  style={{ width: '100%' }}
                  value={[getValues('startUtc'), getValues('endUtc')]}
                  onChange={(dates, dateStrings) => handleDateSelect(dates ? [dates[0]!, dates[1]!] : null, dateStrings)} />
              )}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isLoading ? 'Adding...' : 'Add trip'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddTripPage;