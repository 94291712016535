import * as PiIcons from 'react-icons/pi';
import ReactDOMServer from 'react-dom/server';
import { darken } from './color';

type IconName = keyof typeof PiIcons;

export const createSvg = (color: string, icon: string) => {
  const IconComponent = PiIcons[icon as IconName] as React.ComponentType;

  return (
    <div className='marker-pin' style={{
      background: color
    }}>
      <div className='marker-pin-inner' style={{
        background: darken(color, 40)
      }}>
        <IconComponent />
      </div>
    </div>
  );
};