import { baseApi } from './baseApi';
import { FileResponse } from '../../models/FileResponse';

export const filesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileResponse, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        return {
          url: '/files',
          method: 'POST',
          body: formData,
          formData: true,
        };
      }
    }),
  }),
});

export const {
  useUploadFileMutation,
} = filesApi; 