import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { Form, Input, Button, Select, Col, Row } from 'antd';
import { User } from '../../models/User';
import { useGetCurrentUserQuery, useUpdateCurrentUserMutation } from '../../redux/api/userApi';
import GeocodingSearch from '../../components/GeocodeSearch/GeocodeSearch';
import { GeocodingResult } from '../../models/GeocodingResult';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const schema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    homeLocation: z.object({
        latitude: z.number(),
        longitude: z.number()
    })
});

const OnboardingPage: React.FC = () => {
    const navigate = useNavigate();
    const { data: currentUser } = useGetCurrentUserQuery();
    const [updateUser, { isLoading }] = useUpdateCurrentUserMutation();
    const mapCenter = useSelector((state: RootState) => state.map.center);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm<User>({
        resolver: zodResolver(schema),
        defaultValues: {
            firstName: '',
            lastName: '',
            homeLocation: {
                latitude: 0,
                longitude: 0
            }
        },
    });

    useEffect(() => {
        if (currentUser?.firstName) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    function handleGeocodingResult(selectedResult: GeocodingResult): void {
        setValue('homeLocation.latitude', selectedResult.location.latitude);
        setValue('homeLocation.longitude', selectedResult.location.longitude);
    }

    const onSubmit = async (data: User) => {
        try {
            await updateUser(data).unwrap();
            navigate('/');
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    return (
        <div>
            <h1>Welcome to Wonderly</h1>
            <h2>Let's get to know you better, so we can start logging your adventures</h2>
            <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                <Form.Item
                    label="What should we call you?"
                    validateStatus={errors.firstName ? 'error' : ''}
                    help={errors.firstName ? errors.firstName.message : ''}
                >
                    <Row gutter={8}>
                        <Col span={12}>
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => <Input {...field} placeholder='John' />}
                            />
                        </Col>
                        <Col span={12}>
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => <Input {...field} placeholder='Doe' />}
                            />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    label="Where are you based?"
                    validateStatus={errors.homeLocation ? 'error' : ''}
                    help={errors.homeLocation ? errors.homeLocation.message : ''}
                    tooltip="Adding your home location is optional, but it helps us personalize your experience"
                >
                    <GeocodingSearch
                        placeholder='Enter your address (e.g., "123 Main Street, San Francisco")'
                        mapCenter={mapCenter}
                        onValueChanged={handleGeocodingResult}
                    />
                </Form.Item>

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {isLoading ? 'Setting up...' : 'Get started'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default OnboardingPage; 