import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TripStop } from '../../models/TripStop';
import { setAddNewSpotMarker } from '../../redux/slices/mapSlice';
import { Button, Col, DatePicker, Form, Input, Row, Alert } from 'antd';
import * as z from 'zod';
import IconPicker from '../../components/IconPicker/IconPicker';
import { MapMarkerData } from '../../models/MapMarkerData';
import { Location } from '../../models/Location';
import dayjs, { Dayjs } from 'dayjs'
import { GeocodingResult } from '../../models/GeocodingResult';
import { setMapClickable } from '../../redux/slices/mapSlice';
import { LoadingOutlined } from '@ant-design/icons';
import SidebarHeader from '../../components/Sidebar/SidebarHeader';
import { defaultIcon } from '../../utils/icons';
import { defaultColor } from '../../utils/color';
import { useGetTripsQuery } from '../../redux/api/tripsApi';
import GeocodingSearch from '../../components/GeocodeSearch/GeocodeSearch';
import { useAddTripStopMutation } from '../../redux/api/tripStopsApi';
import { useLazyReverseGeocodeQuery } from '../../redux/api/geocodingApi';

const schema = z.object({
  name: z.string().min(1, 'Place pick a name'),
  description: z.string().nullable(),
  color: z.string().nullable(),
  icon: z.string().nullable(),
  arrivalUtc: z.instanceof(dayjs as unknown as typeof Dayjs, { message: "Please select an arrival date" }),
  location: z.object({
    latitude: z.number().refine(val => val !== 0, {
      message: 'Please select a location on the map',
    }),
    longitude: z.number().refine(val => val !== 0, {
      message: 'Please select a location on the map',
    }),
  })
});

const AddTripStopPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { tripId } = useParams<{ tripId: string }>();

  const { data: trips = [] } = useGetTripsQuery();
  const [addTripStop, { isLoading }] = useAddTripStopMutation();
  const [reverseGeocode, { isLoading: isReverseGeocodingLoading }] = useLazyReverseGeocodeQuery();

  const trip = trips.find(t => t.id === tripId);
  const addNewSpotMarker = useSelector((state: RootState) => state.map.addNewSpotMarker);
  const mapCenter = useSelector((state: RootState) => state.map.center);

  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<TripStop>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      description: null,
      icon: defaultIcon.icon,
      color: defaultColor,
      arrivalUtc: dayjs(),
      location: { latitude: 0, longitude: 0 },
    },
  });

  useEffect(() => {
    dispatch(setMapClickable(true));
    if (trip?.startUtc) {
      setValue('arrivalUtc', dayjs(trip.startUtc));
    }
    return () => {
      dispatch(setMapClickable(false));
      dispatch(setAddNewSpotMarker(null));
    };
  }, [dispatch, setValue, trip]);

  useEffect(() => {
    const fetchReverseQuery = async () => {
      const currentLatitude = getValues('location') as Location | undefined;

      if (addNewSpotMarker?.location &&
        addNewSpotMarker.location.latitude !== currentLatitude?.latitude &&
        addNewSpotMarker.location.longitude !== currentLatitude?.longitude) {

        setValue('location.latitude', addNewSpotMarker.location.latitude);
        setValue('location.longitude', addNewSpotMarker.location.longitude);

        try {
          const results = await reverseGeocode(addNewSpotMarker.location).unwrap();
          if (results.length > 0) {
            setValue('name', results[0].name !== '' ? results[0].name : results[0].address);
          }
        } catch (error) {
          //ignore
        }
      }
    };

    fetchReverseQuery();
  }, [addNewSpotMarker, setValue, getValues, reverseGeocode]);

  const onSubmit = async (data: TripStop) => {
    try {
      await addTripStop({ ...data, tripId: tripId! }).unwrap();
      navigate(`/trip/${tripId}`);
    } catch (err) {
      console.error('Failed to add trip stop:', err);
    }
  };

  const handleIconSelect = (icon: string) => {
    setValue('icon', icon);
    dispatch(setAddNewSpotMarker({ location: addNewSpotMarker?.location, color: getValues('color'), icon: getValues('icon') } as MapMarkerData));
  };

  const handleColorSelect = (color: string) => {
    setValue('color', color);
    dispatch(setAddNewSpotMarker({ location: addNewSpotMarker?.location, color: getValues('color'), icon: getValues('icon') } as MapMarkerData));
  };

  function handleDateSelect(date: Dayjs, dateString: string | string[]): void {
    setValue('arrivalUtc', date);
  }

  function handleGeocodingResult(selectedResult: GeocodingResult): void {
    setValue('location.latitude', selectedResult.location.latitude);
    setValue('location.longitude', selectedResult.location.longitude);
    setValue('name', selectedResult.name ?? selectedResult.address);
    dispatch(setAddNewSpotMarker({ location: selectedResult.location, color: getValues('color'), icon: getValues('icon') } as MapMarkerData));
  }

  return (
    <div>
      <SidebarHeader title='Add a new trip stop' backUrl={trip ? `/trip/${tripId}` : '/trips'} />
      <div className="sidebar-content">
        {!trip && (
          <Alert
            message="Trip not found"
            description="The trip you're trying to add a stop to could not be found."
            type="error"
          />
        )}
        {trip && (
          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <Form.Item
              label="Select a location on the map, or use search"
              validateStatus={errors.location ? 'error' : ''}
              help={errors.location?.latitude ? errors.location.latitude.message : ''}
            >
              <GeocodingSearch
                placeholder='Ex. Golden Gate Bridge, Yosemite National Park'
                mapCenter={mapCenter}
                allowClear={false}
                onValueChanged={handleGeocodingResult}
              />
            </Form.Item>
            <Form.Item
              label="Name"
              validateStatus={errors.name ? 'error' : ''}
              help={errors.name ? errors.name.message : ''}
            >
              <Row wrap={false}>
                <Col flex="none" style={{ paddingRight: '.5rem' }}>
                  <Controller
                    name="icon"
                    control={control}
                    render={({ field }) => (
                      <IconPicker
                        initialIcon={field.value!}
                        initialColor={getValues('color')!}
                        onIconSelect={handleIconSelect}
                        onColorSelect={handleColorSelect}
                      />
                    )}
                  />
                </Col>
                <Col flex="auto">

                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <Input suffix={isReverseGeocodingLoading && (<LoadingOutlined style={{ color: 'rgba(0,0,0,.5)' }} />)} {...field} placeholder='Ex. Golden Gate Bridge, Yosemite National Park' />}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="Arrival date"
              validateStatus={errors.arrivalUtc ? 'error' : ''}
              help={errors.arrivalUtc ? errors.arrivalUtc.message : ''}
            >
              <Controller
                name="arrivalUtc"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    style={{ width: '100%' }}
                    value={field.value || null}
                    onChange={handleDateSelect} />
                )}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              validateStatus={errors.description ? 'error' : ''}
              help={errors.description ? errors.description.message : ''}
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    rows={4}
                    {...field}
                    value={field.value || ''}
                    placeholder='Describe this stop, e.g., "Iconic landmark with great views."'
                  />
                )}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? 'Adding...' : 'Add stop'}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default AddTripStopPage;
