import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Flex, Form, Input, notification } from 'antd';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Register } from '../../models/Register';
import { useRegisterMutation, useLoginMutation } from '../../redux/api/authApi';
import { useDispatch } from 'react-redux';
import { setTokens } from '../../redux/slices/authSlice';

const schema = z.object({
  email: z.string().email("Invalid email format"),
  password: z.string().min(6, "Password must be at least 6 characters"),
  confirmPassword: z.string().min(6, "Password must be at least 6 characters")
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

const RegisterPage: React.FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Register>({
    resolver: zodResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading: registerLoading }] = useRegisterMutation();
  const [login] = useLoginMutation();

  const onSubmit = async (data: Register) => {
    try {
      const { confirmPassword, ...registerData } = data;
      await register(data).unwrap();
      const loginResult = await login(registerData).unwrap();
      dispatch(setTokens({
        accessToken: loginResult.accessToken,
        refreshToken: loginResult.refreshToken,
      }));
      navigate('/');
    } catch (error) {
      notification.error({
        message: 'Registration failed',
        description: error instanceof Error ? error.message : 'An unknown error occurred',
      });
    }
  };

  return (
    <div>
      <h1>Start your journey</h1>
      <h2>Sign up to track your journeys and build a library of memories to explore anytime</h2>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Form.Item
          label="What's your email address?"
          validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input {...field} placeholder='j.doe@email.com' />}
          />
        </Form.Item>

        <Form.Item
          label="What password do you want to use?"
          validateStatus={errors.password ? 'error' : ''}
          help={errors.password ? errors.password.message : ''}
        >
          <Controller
            name="password"
            control={control}
            render={({ field }) => <Input.Password {...field} placeholder="input password" />}
          />
        </Form.Item>

        <Form.Item
          label="Confirm your password"
          validateStatus={errors.confirmPassword ? 'error' : ''}
          help={errors.confirmPassword ? errors.confirmPassword.message : ''}
        >
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => <Input.Password {...field} placeholder="confirm password" />}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right' }}>
          <Flex justify='space-between' align='center'>
            <Button type="text" htmlType="button" onClick={() => navigate('/auth/login')}>
              I already have an account
            </Button>
            <Button type="primary" htmlType="submit" loading={registerLoading}>
              {registerLoading ? 'Signing up...' : 'Sign up'}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterPage;