import React from 'react';
import { Outlet } from 'react-router-dom';

const AuthLayout: React.FC = () => {
    return (
        <div className='auth'>
            <div className='auth_background'>
                <img src='/logo_white.png' alt='wonderly' />
            </div>
            <div className='auth_content'>
                <Outlet />
            </div>
        </div>
    );
};

export default AuthLayout;
